// same enums exist in Backend. If changes are applied here,
// please update src/api/common/vouchers.enum.ts

export enum VoucherType {
    Marker = 0,
    PercentageOneTime = 1,
    PercentageMultipleTimes = 2,
    AmountOneTime = 3,
    AmountUntilFinish = 4,
    InternalMarker = 5
}

export enum VoucherPayerType {
    Customer = 1,
    Platform = 2
}
