import { IBusinessTypeOptionItem, IOptionItem } from '../declarations/option-item';
import { AllergenType } from '../enums/allergen-type.enum';
import { BusinessType } from '../enums/business-type.enum';
import { DietType } from '../enums/diet-type.enum';
import { FoodType } from '../enums/food-type.enum';
import { VoucherType, VoucherPayerType } from '../enums/vouchers.enum';

export class ConfirmDeleteMessages {

  private static constructConfirmationMessage(typeOfItemToDelete: string): string {
    return `Сигурни ли сте, че искате да изтриете безвъзвратно ${typeOfItemToDelete}?`;
  }
}

export const HUBSPOT_TO_BUSINESS_MAP: Record<BusinessType, string> = {
  [BusinessType.Bakery]: 'Bakery',
  [BusinessType.Hotel]: 'Hotel',
  [BusinessType.RestaurantCathering]: 'Restaurant',
  [BusinessType.Sushi]: 'Sushi',
  [BusinessType.ButcherShop]: 'Grocery store (small)',
  [BusinessType.FruitVegetable]: 'Fruit&veggies',
  [BusinessType.Buffet]: 'Restaurant',
  [BusinessType.GroceryStore]: 'Grocery store (small)',
  [BusinessType.FishStore]: 'Grocery store (small)',
  [BusinessType.GasStation]: 'Petrol station',
  [BusinessType.Grower]: 'Producer',
  [BusinessType.Other]: 'Drinks',
}

export const BUSINESS_TYPES: IBusinessTypeOptionItem[] = [{
  value: BusinessType.Bakery,
  name: 'BAKERY',
  image: 'type-bakery-pastry-shop.jpg'
}, {
  value: BusinessType.Hotel,
  name: 'HOTEL',
  image: 'type-hotel.jpg'
}, {
  value: BusinessType.RestaurantCathering,
  name: 'RESTAURANT_AND_CATERING',
  image: 'type-buffet.jpg'
}, {
  value: BusinessType.Sushi,
  name: 'SUSHI',
  image: 'type-sushi.jpg'
}, {
  value: BusinessType.ButcherShop,
  name: 'MEAT_SHOP',
  image: 'type-butcher-shop.jpg'
}, {
  value: BusinessType.FruitVegetable,
  name: 'FRUITS_AND_VEGETABLES',
  image: 'type-greengrocer.jpg'
}, {
  value: BusinessType.Buffet,
  name: 'BUFFET',
  image: 'type-buffet.jpg'
}, {
  value: BusinessType.GroceryStore,
  name: 'FOOD_STORE',
  image: 'type-grocery-store.jpg'
}, {
  value: BusinessType.FishStore,
  name: 'FISH_STORE',
  image: 'type-fish.jpg'
}, {
  value: BusinessType.GasStation,
  name: 'GAS_STATION',
  image: 'type-gas-station.jpg'
}, {
  value: BusinessType.Grower,
  name: 'MANUFACTURER',
  image: 'type-wholesaler.jpg'
}, {
  value: BusinessType.Other,
  name: 'OTHERS',
  image: 'type-others.jpg'
}];

export const ALLERGENES_TYPES: IOptionItem[] = [{
  value: AllergenType.Crustaceans,
  name: 'CRUSTACEANS'
}, {
  value: AllergenType.Fish,
  name: 'FISH'
}, {
  value: AllergenType.Eggs,
  name: 'EGGS'
}, {
  value: AllergenType.Soy,
  name: 'SOY'
}, {
  value: AllergenType.Nuts,
  name: 'NUTS'
}, {
  value: AllergenType.Peanuts,
  name: 'PEANUTS'
}, {
  value: AllergenType.Celery,
  name: 'CELERY'
}, {
  value: AllergenType.Mustard,
  name: 'MUSTARD'
}, {
  value: AllergenType.Sesame,
  name: 'SESAME'
}, {
  value: AllergenType.Lupine,
  name: 'LUPINE'
}, {
  value: AllergenType.Molluscs,
  name: 'MOLLUSCS'
}, {
  value: AllergenType.SulfurDioxide,
  name: 'SULFUR_DIOXIDE'
}, {
  value: AllergenType.DairyProducts,
  name: 'DAIRY_PRODUCTS'
}, {
  value: AllergenType.Gluten,
  name: 'GLUTEN'
}];

export const FOOD_TYPES: IOptionItem[] = [{
  value: FoodType.MainDish,
  name: 'FOODS'
}, {
  value: FoodType.PastriesAndSweets,
  name: 'BAKED_GOODS'
}, {
  value: FoodType.FoodStuffs,
  name: 'FOOD_PRODUCTS'
}, {
  value: FoodType.Other,
  name: 'OTHERS'
}];

export const DIET_TYPES: IOptionItem[] = [{
  value: DietType.Vegan,
  name: 'VEGAN'
}, {
  value: DietType.Vegetarian,
  name: 'VEGETARIAN'
}, {
  value: DietType.Standard,
  name: 'STANDARD'
}];

export const VOUCHER_TYPES: IOptionItem[] = [{
  value: VoucherType.Marker,
  name: 'MARKER'
}, {
  value: VoucherType.PercentageOneTime,
  name: 'SINGLE_DISCOUNT_%'
}, {
  value: VoucherType.PercentageMultipleTimes,
  name: 'MULTIPLE_DISCOUNT_%'
}, {
  value: VoucherType.AmountOneTime,
  name: 'SINGULAR_DISCOUNT_$'
}, {
  value: VoucherType.AmountUntilFinish,
  name: 'UNTIL_EXHAUSTED_$'
}, {
  value: VoucherType.InternalMarker,
  name: 'INTERNAL_MARKER'
}];

export const VOUCHER_PAYER_TYPES: IOptionItem[] = [{
  value: VoucherPayerType.Customer,
  name: 'PARTNER'
}, {
  value: VoucherPayerType.Platform,
  name: 'PLATFORM'
}];

export const TIMEZONES_LIST = [
  'Europe/Sofia',
  'Europe/Bucharest',
];
