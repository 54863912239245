<h1 mat-dialog-title>{{'SELECT_PERIOD' | translate}}</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill" class="w-100">
    <mat-label>{{'PERIOD' | translate}}</mat-label>
    <mat-date-range-input [formGroup]="filter" [rangePicker]="filterRangerPicker">
      <input matStartDate placeholder="От" formControlName="from">
      <input matEndDate placeholder="До" formControlName="to">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="filterRangerPicker"></mat-datepicker-toggle>
    <mat-date-range-picker #filterRangerPicker></mat-date-range-picker>
  </mat-form-field>
  <div class="action-buttons">
    <button mat-raised-button color="primary" (click)="dialogRef.close(filter.value)" class="mr-2">{{'SAVE' | translate}}</button>
    <button mat-raised-button (click)="dialogRef.close(false)" class="ml-2">{{'DECLINE_2' | translate}}</button>
  </div>
</div>
