export enum AllergenType {
  Crustaceans = 1,
  Fish = 2,
  Eggs = 3,
  Soy = 4,
  Nuts = 5,
  Peanuts = 6,
  Celery = 7,
  Mustard = 8,
  Sesame = 9,
  Lupine = 10,
  Molluscs = 11,
  SulfurDioxide = 12,
  DairyProducts = 13,
  Gluten = 14
}
