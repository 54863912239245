import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DEFAULT_DATE_FORMAT } from '../config/app.config';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {

  transform(value: Date, timezone?: string): any {
    return super.transform(value, DEFAULT_DATE_FORMAT, timezone);
  }
}
