import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent {
  filter: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<DateFilterComponent>,
  ) {
    this.filter = new FormGroup({
      from: new FormControl(),
      to: new FormControl()
    });
  }
}
