import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../../core/user.service';
import { Roles } from '../enums/roles.enum';
import { hasRole } from '../helpers/has-role';

@Injectable({
    providedIn: 'root'
  })

export class AdminGuard implements CanActivate {

  constructor(private router: Router, private userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.userService.isManagerLogged() && hasRole([Roles.Admin])) {
        // logged in so return true
        return true;
    }

    // not logged in so redirect dashboard home
    this.router.navigate(['/']);
    return false;
  }
}
