import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from '../../core/user.service';

@Injectable()
export class LoggedInRedirect implements CanActivate {

  constructor(
    private readonly router: Router,
    private readonly userService: UserService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot) {
    if (this.userService.isManagerLogged()) {
      this.router.navigate(['/inventory']);
      return false;
    }

    return true;
  }
}