import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Roles } from '../../../shared/enums/roles.enum';
import { localeLanguages } from '../../../shared/helpers/utils';

import { ToastrService } from 'ngx-toastr';
import { iif, Subscription, withLatestFrom } from 'rxjs';

import { UserService } from '../../../core/user.service';
import {
  DEFAULT_ERROR_HEADING, DEFAULT_LANG_KEY, DEFAULT_SUCCESS_HEADING,
  LOCAL_STORAGE_LANG_KEY, LOCAL_STORAGE_TOKEN_KEY,
  LOCAL_STORAGE_USER_KEY, RECAPTCHA_API_KEY
} from '../../../shared/config/app.config';

@Component({
  selector: 'app-login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html',
  providers: [{ provide: Window, useValue: window }]
})
export class LoginComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  form: FormGroup;
  loading: boolean;
  returnUrl: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly userService: UserService,
    private readonly toastrService: ToastrService,
    private readonly translateService: TranslateService,
    private window: Window
  ) { }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    const langParam = this.route.snapshot.queryParams.lang;

    this.form = new FormGroup({
      locale: new FormControl('bg'),
      email: new FormControl(undefined, [Validators.required, Validators.email]),
      password: new FormControl(undefined, [Validators.required]),
      recaptcha: new FormControl('')
    });
    this.subscriptions.add(this.form.get('locale').valueChanges.subscribe((locale) => {
      this.translateService.use(locale);
      localStorage.setItem(LOCAL_STORAGE_LANG_KEY, locale);
    }));

    if (localeLanguages.indexOf(langParam) !== -1) {
      this.form.patchValue({ locale: langParam });
    } else {
      const locale = localStorage.getItem(LOCAL_STORAGE_LANG_KEY) || DEFAULT_LANG_KEY
      this.form.patchValue({ locale });
    }

    this.window['grecaptcha'].ready(() => {
      setInterval(() => {
        this.resetRecaptcha();
      }, 60 * 1000);
      this.resetRecaptcha();
    });

    if (this.route.snapshot.queryParams.action && this.route.snapshot.queryParams.type) {
      const payload = {
        registrationActivationToken: this.route.snapshot.queryParams.action
      }
      const isUser = (parseInt(this.route.snapshot.queryParams.type, 10) === Roles.User);
      const successConfirmMsgLabel = isUser ? 'CONFIRM_USER_EMAIL_SUCCESS' : 'CONFIRM_COMPANY_EMAIL_SUCCESS';
      iif(() => {
        return isUser;
      },
        this.userService.confirmUserEmail(payload),
        this.userService.confirmManagerEmail(payload)
      ).pipe(
        withLatestFrom(
          this.translateService.get(successConfirmMsgLabel)
        )
      )
        .subscribe((result) => {
          debugger;
          this.toastrService.success(result[1], DEFAULT_SUCCESS_HEADING);
        }, ({ error }) => {
          this.toastrService.error(error.message, DEFAULT_ERROR_HEADING);
        });
    }
  }

  get isUser(): boolean {
    return (parseInt(this.route.snapshot.queryParams.type, 10) === Roles.User);
  }

  get isPassReset(): boolean {
    return this.route.snapshot.queryParams.passReset;
  }

  private resetRecaptcha() {
    this.window['grecaptcha'].execute(RECAPTCHA_API_KEY, {
      action: 'login'
    }).then((token) => {
      this.form.patchValue({
        recaptcha: token
      })
    });
  }

  login(): void {
    this.loading = true;
    const data = this.form.value;
    this.subscriptions.add(this.userService.loginManager(data)
      .subscribe((response) => {
        localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, response.token);
        localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify({ ...response.user }));
        if (response.user.locale) {
          localStorage.setItem(LOCAL_STORAGE_LANG_KEY, response.user.locale);
        }

        this.window.location.reload();
      }, ({ error }) => {
        this.loading = false;
        this.toastrService.error(error.message, DEFAULT_ERROR_HEADING);
        this.resetRecaptcha();
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
