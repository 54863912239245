import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs';

import { LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_USER_KEY } from '../config/app.config';
import { Roles } from '../enums/roles.enum';
import { UserService } from '../../core/user.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private readonly userService: UserService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const headers = {};
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
    const currentManager = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
    const userRole = currentManager?.role;
    if (token) {
      headers['x-access-token'] = token;
    }

    if (userRole && [Roles.Admin].indexOf(userRole) > -1) {
      const company = this.userService.temporaryCompany;
      const headerCompanyId = (company && company.id > 0) ? company.id : currentManager.companyId;
      headers['x-company-id'] = String(headerCompanyId);
    }

    request = request.clone({
      setHeaders: headers
    });

    return next.handle(request);
  }
}
