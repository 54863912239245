import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '../../environments/environment';
import { IVoucher } from '../shared/declarations/voucher';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {
  constructor(private http: HttpClient) { }

  list(): Observable<any> {
    return this.http.get(`${env.apiV2Url}/admin/voucher/all`).pipe(
      map((result : {data: {vouchers: any}}) => {
        return result.data;
      })
    );
  }

  create(data: IVoucher): Observable<any> {
    return this.http.post(`${env.apiV2Url}/admin/voucher/create`, data);
  }

  // TODO: we only send id, description
  edit(data: Pick<IVoucher, 'id' | 'description'>): Observable<any> {
    return this.http.post(`${env.apiV2Url}/admin/voucher/edit`, data);
  }

  delete(id: number): Observable<any> {
    return this.http.post(`${env.apiV2Url}/admin/voucher/delete`, {id: id});
  }

}
