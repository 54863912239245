import { Injectable } from '@angular/core';
import {State, Action, StateContext, Selector} from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from '../../core/company.service';
import { DEFAULT_ERROR_HEADING } from '../config/app.config';
import { ICompany } from '../declarations/company';
import { IProduct } from '../declarations/product';
import { IRestaurant } from '../declarations/restaurant';
import { LoadSettings, SelectProduct } from './settings.actions';
import {UserService} from '../../core/user.service';

export interface SettingsStateModel {
    resturants: IRestaurant[],
    company: ICompany,
    selectedProduct: IProduct,
    products: IProduct[]
}
@State<SettingsStateModel>({
    name: 'settings',
    defaults: {
        resturants: [],
        company: null,
        selectedProduct: null,
        products: []
    }
})
@Injectable()
export class SettingsState {
    constructor(
        private companyService: CompanyService,
        private toastrService: ToastrService,
        private userService: UserService
    ) { }

    @Action(LoadSettings)
    loadSettings(ctx: StateContext<any>) {
      const companyId = this.userService.getManagerCompanyId();
      if (companyId !== -1) {
        return this.companyService.getSettings(companyId).subscribe(({ company, products, restaurants }) => {
          const state = ctx.getState();
          ctx.setState({
            ...state,
            company,
            products,
            restaurants
          });
        }, () => {
          this.toastrService.error('Моля опитайте пак', DEFAULT_ERROR_HEADING);
        });
      }
    }

    @Action(SelectProduct)
    selectProduct(ctx: StateContext<any>, { selectedProduct }: SelectProduct) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            selectedProduct
        });
    }
}
