import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from "@angular/common/http"
import { Injectable } from "@angular/core";
import { LOCAL_STORAGE_LANG_KEY, DEFAULT_LANG_KEY } from "../shared/config/app.config";

@Injectable()
export class NgrokInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    request = request.clone({
      setHeaders: {
        'ngrok-skip-browser-warning': 'true'
      }
    })
    return next.handle(request);
  }
}
