<nav class="navbar-header" [class.small-menu]="sideMenuIsMobile || (isManager && !userIsOnInventoryRoute) || (isAdmin && !isCompanySelected && !sideMenuIsMobile)">
  <div class="nav-top-menu">
    <div class="left-header-container">
      <ng-container *ngIf="sideMenuIsMobile">
        <button id="menu-toggle-btn" mat-icon-button (click)="inputSideNav.toggle()">
          <mat-icon *ngIf="!inputSideNav.opened">
            menu
          </mat-icon>
          <mat-icon *ngIf="inputSideNav.opened">
            close
          </mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="(!isAdmin || (isAdmin && !!selectedCompany)) && !sideMenuIsMobile">
        <!-- <a class="navbar-menu-item" routerLink="/financials/profits" routerLinkActive="active-menu-item">
          <mat-icon>money</mat-icon>
          {{'INCOME' | translate}}
        </a> -->
        <a class="navbar-menu-item" routerLink="/inventory/verify-order" routerLinkActive="active-menu-item" [routerLinkActiveOptions]="{exact: true}">
          <mat-icon>history</mat-icon>
          {{'PIN' | translate}} {{'CODE' | translate}}
        </a>
        <a class="navbar-menu-item" routerLink="/inventory/order-history" routerLinkActive="active-menu-item" [routerLinkActiveOptions]="{exact: true}">
          <mat-icon>check_circle</mat-icon>
          {{'FINISHED_ORDERS' | translate}}
        </a>
      </ng-container>
      <mat-form-field *ngIf="(!isAdmin || (isAdmin && !!selectedCompany))">
        <mat-select [formControl]="selectPlace">
          <mat-option *ngFor="let restaurant of (restaurants$ | async)" [value]="restaurant">
            {{restaurant.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <ng-container *ngIf="isAdmin && !!selectedCompany">
    <div class="nav-bottom-menu" [class.admin-layout]="!isManager" *ngIf="!sideMenuIsMobile">
      <ng-container *ngIf="selectedCompany.isActive">
        <span class="block-account-label">{{'TO_BLOCK_ACCOUNT' | translate}}</span>
        <button type="button" class="disable-account-button" mat-raised-button color="accent" (click)="disableAccount()">{{'BLOCK_ACCOUNT' | translate}}</button>
      </ng-container>
      <ng-container *ngIf="!selectedCompany.isActive">
        <span class="block-account-label">{{'TO_ACTIVATE_ACCOUNT' | translate}}</span>
        <button type="button" class="disable-account-button" mat-raised-button color="primary" (click)="activateAccount()">{{'ACTIVATE_ACCOUNT' | translate}}</button>
      </ng-container>
    </div>
  </ng-container>
</nav>