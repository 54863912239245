import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
} from "@angular/common/http"
import { Injectable } from "@angular/core";
import { LOCAL_STORAGE_LANG_KEY, DEFAULT_LANG_KEY } from "../shared/config/app.config";

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler) {
        request = request.clone({
            setHeaders: {
                'Accept-Language': localStorage.getItem(LOCAL_STORAGE_LANG_KEY) || DEFAULT_LANG_KEY
            }
        })
        return next.handle(request);
    }
}