<div class="container-fluid">
  <div class="row">
    <form [formGroup]="form" autocomplete="off" class="col-md-6 login-form">
      <div class="d-flex justify-content-center mb-5">
        <img src="assets/images/logo.png" class="logo" />
      </div>

      <mat-form-field appearance="standard">
        <mat-label>
          {{ 'CHOOSE_LANGUAGE' | translate }}
        </mat-label>
        <mat-select formControlName="locale">
          <mat-option value="bg">Български</mat-option>
          <mat-option value="en">English</mat-option>
          <mat-option value="ro">Română</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mb-3 w-100">
        <mat-label>
          {{ 'SIGNUP_PASSWORD' | translate }}
        </mat-label>
        <input type="password" formControlName="password" matInput />
        <mat-error *ngIf="!form.get('password').valid">
          {{ 'ERROR_PASSWORD_LENGTH' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="mb-3 w-100">
        <mat-label>
          {{ 'SIGNUP_PASSWORD_VERIFY' | translate }}
        </mat-label>
        <input type="password" formControlName="passwordAgain" matInput />
        <mat-error *ngIf="form.get('passwordAgain').hasError('passwordsNotEqual')">
          {{ 'ERROR_PASSWORD_MATCH' | translate }}
        </mat-error>
      </mat-form-field>

      <div class="center-div">
        <button mat-button color="primary" (click)="resetPassword()" [disabled]="!form.valid || form.pristine">
          <mat-spinner *ngIf="loading" class="loading-spinner"></mat-spinner>
          <span>
            {{ 'RESET_PASSWORD_SUBMIT' | translate}}</span>
        </button>
        <div class="text-center mt-3">
          <p><strong><a routerLink="/login" class="primary-text-color">{{ 'RETURN_TO_LOGIN_LABEL' | translate }}</a></strong></p>
        </div>
      </div>
    </form>
    <div class="col-md-6 mt-5">
      <img src="assets/images/chef.png" alt="Вход" class="right-image">
    </div>
  </div>
</div>