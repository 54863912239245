import { Component, OnDestroy, ViewChild } from '@angular/core';
import { debounceTime, Observable, of, Subscription, withLatestFrom } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { phoneRegex } from '../../../shared/helpers/utils';
import { localeLanguages } from '../../../shared/helpers/utils';

import { ToastrService } from 'ngx-toastr';

import {
  RepeatPasswordStateMatcher,
  EmailValidation,
  PasswordValidation,
  RepeatPasswordValidator,
  AgreeWithTermsValidator
} from '../../../shared/validators/validators';
import { UserService } from '../../../core/user.service';
import { DEFAULT_ERROR_HEADING, DEFAULT_LANG_KEY, DEFAULT_SUCCESS_HEADING, LOCAL_STORAGE_LANG_KEY, RECAPTCHA_API_KEY, TERMS_URL } from '../../../shared/config/app.config';
import {BUSINESS_TYPES, HUBSPOT_TO_BUSINESS_MAP} from '../../../shared/config/constants';
import { IBusinessTypeOptionItem } from '../../../shared/declarations/option-item';
import { TranslateService } from '@ngx-translate/core';
import { MatStepper } from '@angular/material/stepper';
import {environment} from '../../../../environments/environment';

interface ISignUpDto {
  companyName: string;
  email: string;
  password: string;
  passwordAgain: string;
  promoCode: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  ein: string;
  address: string;
  latitude: string;
  longitude: string;
  agreeWithTerms: string;
  businessType: string;
  recaptcha: string;
  locale: string;
}
@Component({
  selector: 'app-register',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  providers: [{ provide: Window, useValue: window }]
})
export class SignupComponent implements OnDestroy {
  @ViewChild('stepper') stepper: MatStepper;

  private subscriptions: Subscription = new Subscription();
  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public thirdFormGroup: FormGroup;
  public passwordsMatcher = new RepeatPasswordStateMatcher();
  public loading: boolean;
  public businessTypesList: IBusinessTypeOptionItem[];
  public promoCodeError: string;
  public environment = environment;

  constructor(
    private readonly route: ActivatedRoute,
    private userService: UserService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private window: Window
  ) {
    this.businessTypesList = BUSINESS_TYPES;

    let langParam = null;
    if (this.route.snapshot.queryParams.lang) {
      langParam = this.route.snapshot.queryParams.lang.split("?")[0];
    }

    this.userService.logout();
    this.createForm();

    const promoCodeCtrl = this.firstFormGroup.get('promoCode');

    this.window['grecaptcha'].ready(() => {
      setInterval(() => {
        this.resetRecaptcha();
      }, 60 * 1000);
      this.resetRecaptcha();
    });

    this.subscriptions.add(promoCodeCtrl.valueChanges.pipe(
      debounceTime(250)
    ).subscribe(async (promoCode) => {
      const verifyPromoCodeCtrl = this.firstFormGroup.get('verifyPromoCode');
      if (!!promoCode) {
        try {
          const { locale } = this.firstFormGroup.value;
          const valid = await this.userService.verifyPromoCode({ promoCode, locale }).toPromise();
          if (valid) {
            verifyPromoCodeCtrl.setValue(true);
            promoCodeCtrl.setErrors(null)
          } else {
            verifyPromoCodeCtrl.setValue(null);
            promoCodeCtrl.setErrors({ invalidPromoCode: true });
          }
        } catch ({ error }) {
          this.promoCodeError = error.message;
          verifyPromoCodeCtrl.setValue(null);
          promoCodeCtrl.setErrors({ invalidPromoCode: true });
        }
      } else {
        verifyPromoCodeCtrl.setValue(true);
        promoCodeCtrl.setErrors(null)
      }
    }));

    this.subscriptions.add(this.firstFormGroup.get('locale').valueChanges.subscribe((locale) => {
      if (locale) {
        this.translateService.use(locale);
        localStorage.setItem(LOCAL_STORAGE_LANG_KEY, locale);
      }
    }));

    if (localeLanguages.indexOf(langParam) !== -1) {
      this.firstFormGroup.patchValue({ locale: langParam })
    }
    this.firstFormGroup.get('locale').setValue(localStorage.getItem(LOCAL_STORAGE_LANG_KEY) || DEFAULT_LANG_KEY);
  }

  private resetRecaptcha() {
    this.window['grecaptcha'].execute(RECAPTCHA_API_KEY, {
      action: 'login'
    }).then((token) => {
      this.thirdFormGroup.patchValue({
        recaptcha: token
      })
    });
  }

  selectBusinessType(businessType: IBusinessTypeOptionItem) {
    this.thirdFormGroup.patchValue({
      businessType: businessType.value
    })
  }

  onAutocompleteSelected({ formatted_address }): void {
    this.secondFormGroup.patchValue({
      address: formatted_address
    });
  }

  onLocationSelected(event): void {
    this.secondFormGroup.patchValue({
      latitude: event.latitude,
      longitude: event.longitude
    });
  }

  onSubmit(): void {
    this.loading = true;
    const formData: ISignUpDto = {
      ...this.firstFormGroup.value,
      ...this.secondFormGroup.value,
      ...this.thirdFormGroup.value,
    };

    const payload = {
      company: {
        type: formData.businessType,
        email: formData.email,
      },
      manager: {
        email: formData.email,
        password: formData.password,
        firstName: formData.firstName,
        lastName: formData.lastName,
        promoCode: formData.promoCode
      },
      restaurant: {
        name: formData.companyName,
        phoneNumber: formData.phoneNumber,
        address: formData.address,
        latitude: formData.latitude,
        longitude: formData.longitude
      },
      recaptcha: formData.recaptcha,
      locale: formData.locale,
    };

    this.subscriptions.add(this.userService.registerCompanyV2(payload).pipe(
      withLatestFrom(
        this.translateService.get('SIGNUP_SUCCESS')
      )
    ).subscribe((result) => {
      this.sendHubSpotFormSubmission(formData);
      this.loading = false;
      this.toastrService.success(result[1], DEFAULT_SUCCESS_HEADING);
      const localeCtrl = this.firstFormGroup.get('locale');
      const currentLang = localeCtrl.value;
      this.firstFormGroup.reset();
      this.firstFormGroup.patchValue({
        locale: currentLang
      });
      this.secondFormGroup.reset();
      this.thirdFormGroup.reset();
      this.stepper.selectedIndex = 0;
      this.resetRecaptcha();

      if (this.window['fbq']) {
        this.window['fbq']('track', 'CompleteRegistration', {
          content_name: 'Signup',
          status: 'complete'
        });
      }
      if (this.window['gtag']) {
        this.window['gtag']('event', 'CompleteLead', {
          'event_category': 'Application',
          'event_label': 'F-PLATFORM'
        });
      }
    }, ({ error }) => {
      this.loading = false;
      this.toastrService.error(error.message, DEFAULT_ERROR_HEADING);
      this.resetRecaptcha();
    }));
  }

  private createForm() {
    this.firstFormGroup = new FormGroup({
      locale: new FormControl('bg'),
      companyName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, ...EmailValidation]),
      password: new FormControl('', [Validators.required, ...PasswordValidation]),
      passwordAgain: new FormControl('', [Validators.required]),
      promoCode: new FormControl(''),
      verifyPromoCode: new FormControl(true, [Validators.required]),
    }, { validators: [RepeatPasswordValidator] });
    this.secondFormGroup = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl(null, [Validators.required, Validators.pattern(phoneRegex)]),
      ein: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      latitude: new FormControl(null),
      longitude: new FormControl(null),
    });
    this.thirdFormGroup = new FormGroup({
      agreeWithTerms: new FormControl(false, [Validators.requiredTrue]),
      businessType: new FormControl(null, [Validators.required]),
      recaptcha: new FormControl(''),
    });

    // this.firstFormGroup.statusChanges.pipe(debounceTime(2000)).subscribe(() => {
    //   if (this.firstFormGroup.valid && !this.firstFormGroup.pristine) {
    //     this.stepper.next();
    //   }
    // });

    // this.secondFormGroup.statusChanges.pipe(debounceTime(2000)).subscribe(() => {
    //   if (this.secondFormGroup.valid && !this.secondFormGroup.pristine) {
    //     this.stepper.next();
    //   }
    // });
  }

  private sendHubSpotFormSubmission(formData) {
    // const storeType = BUSINESS_TYPES.find((x) => x.value === parseInt(formData.businessType, 10));
    const storeType = HUBSPOT_TO_BUSINESS_MAP[formData.businessType];
    const fields = [
      {
        objectTypeId: '0-1',
        name: 'email',
        value: formData.email
      },
      {
        objectTypeId: '0-1',
        name: "phone",
        value: formData.phoneNumber
      },
      {
        objectTypeId: '0-1',
        name: "firstname",
        value: `${formData.firstName}`
      },
      {
        objectTypeId: '0-1',
        name: "lastname",
        value: formData.lastName
      },
      {
        objectTypeId: '0-1',
        name: "address",
        value: formData.address
      },
      {
        objectTypeId: '0-1',
        name: "company",
        value: formData.companyName
      },
      {
        objectTypeId: '0-1',
        name: "store_type",
        value: storeType ?? 'Restaurant'
      },
      {
        objectTypeId: '0-1',
        name: "hs_language",
        value: formData.locale
      },
      {
        objectTypeId: '0-1',
        name: "bulstat",
        value: formData.ein
      },
      {
        objectTypeId: '0-1',
        name: "fb_promo_code",
        value: formData.promoCode
      },
      {
        objectTypeId: '0-1',
        name: "store_name",
        value: formData.companyName
      },
    ];
    this.userService.sendHubSpotData(fields).toPromise();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get selectedBusinessType(): number {
    return this.thirdFormGroup.value.businessType;
  }

  get locale(): string {
    return this.firstFormGroup.get('locale').value
  }

  get addressValue(): string {
    const { address } = this.secondFormGroup.value;
    return address || '';
  }

  get disableSubmit(): boolean {
    return !this.firstFormGroup.valid || this.firstFormGroup.pristine ||
      !this.secondFormGroup.valid || this.secondFormGroup.pristine ||
      !this.thirdFormGroup.valid || this.thirdFormGroup.pristine;
  }
}
