import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { DEFAULT_ERROR_HEADING, DEFAULT_LANG_KEY, DEFAULT_SUCCESS_HEADING, LOCAL_STORAGE_LANG_KEY, RECAPTCHA_API_KEY } from '../../../shared/config/app.config';

import { UserService } from '../../../core/user.service';
import { PasswordValidation, RepeatPasswordValidator } from '../../../shared/validators/validators';
import { localeLanguages } from '../../../shared/helpers/utils';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { iif, Subscription, withLatestFrom } from 'rxjs';
import { Roles } from '../../../shared/enums/roles.enum';

@Component({
  selector: 'app-change-password',
  styleUrls: ['./change-password.component.scss'],
  templateUrl: './change-password.component.html',
  providers: [{ provide: Window, useValue: window }]
})
export class ChangePasswordComponent implements OnInit {
  private subscriptions: Subscription = new Subscription();

  public loading: boolean;
  public form: FormGroup;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly userService: UserService,
    private readonly toastrService: ToastrService,
    private readonly translateService: TranslateService,
    private window: Window
  ) { }

  ngOnInit() {
    this.userService.logout();
    const langParam = this.route.snapshot.queryParams.lang;

    this.form = this.formBuilder.group({
      locale: ['bg'],
      password: new FormControl('', [Validators.required, ...PasswordValidation]),
      passwordAgain: new FormControl('', [Validators.required]),
      recaptcha: [null],
    }, { validators: [RepeatPasswordValidator] });

    this.subscriptions.add(this.form.get('locale').valueChanges.subscribe((locale) => {
      this.translateService.use(locale);
      localStorage.setItem(LOCAL_STORAGE_LANG_KEY, locale);
    }));

    if (localeLanguages.indexOf(langParam) !== -1) {
      this.form.patchValue({ locale: langParam })
    }

    this.window['grecaptcha'].ready(() => {
      setInterval(() => {
        this.resetRecaptcha();
      }, 60 * 1000);
      this.resetRecaptcha();
    });

    this.form.get('locale').setValue(localStorage.getItem(LOCAL_STORAGE_LANG_KEY) || DEFAULT_LANG_KEY);
  }

  private resetRecaptcha() {
    this.window['grecaptcha'].execute(RECAPTCHA_API_KEY, {
      action: 'login'
    }).then((token) => {
      this.form.patchValue({
        recaptcha: token
      })
    });
  }

  resetPassword() {
    this.loading = true;
    const payload = {
      ...this.form.value,
      confirmPassword: this.form.value.passwordAgain,
      token: this.route.snapshot.queryParams.id
    };

    iif(() => {
      return parseInt(this.route.snapshot.queryParams.type, 10) === Roles.User;
    },
      this.userService.createNewPasswordUser(payload),
      this.userService.updatePasswordManagerExternal(payload)
    ).pipe(
      withLatestFrom(
        this.translateService.get('CHANGE_PASSWORD_SUCCESS')
      )
    )
      .subscribe((result) => {
        this.loading = false;
        this.toastrService.success(result[1], DEFAULT_SUCCESS_HEADING);
        this.router.navigate(['/login'], { queryParams: { type: parseInt(this.route.snapshot.queryParams.type, 10), passReset: true }});
      }, ({ error }) => {
        this.loading = false;
        this.toastrService.error(error.message, DEFAULT_ERROR_HEADING);
        this.resetRecaptcha();
      });
  }
}
