import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import { TranslateModule } from '@ngx-translate/core';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { GoogleMapsModule } from '@angular/google-maps'
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginatorModule } from '@angular/material/paginator';

import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { ToastrService } from 'ngx-toastr';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { DateTimeFormatPipe } from './pipes/date-time-format.pipe';
import { HasRoleDirective } from './directives/has-role.directive';
import { UploadPhotosComponent } from './components/upload-photos/upload-photos.component';
import { DragNdropDirective } from './directives/drag-n-drop.directive';
import { OrderStatusPipe } from './pipes/order-status.pipe';
import { ConfirmActionComponent } from './components/confirm-action/confirm-action.component';
import { SelectCompanyComponent } from './components/select-company/select-company.component';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS} from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from './helpers/appDateAdapter';
import { MatTabsModule } from '@angular/material/tabs';
import { ScrollingModule } from '@angular/cdk/scrolling';

const sharedMaterialModules = [
  MatTabsModule,
  MatTableModule,
  MatSidenavModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatDialogModule,
  MatIconModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatExpansionModule,
  MatTooltipModule,
  MatBadgeModule,
  MatChipsModule,
  MatSlideToggleModule,
  MatAutocompleteModule,
  MatPaginatorModule
];

const sharedComponents = [
  SidebarComponent,
  UploadPhotosComponent,
  ConfirmActionComponent,
  SelectCompanyComponent,
  DateFilterComponent,
];

const sharedAngularModules = [
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  GoogleMapsModule
];

const pipes = [
  YesNoPipe,
  DateFormatPipe,
  DateTimeFormatPipe,
  OrderStatusPipe
];

const directives = [
  HasRoleDirective,
  NumbersOnlyDirective,
  DragNdropDirective
];

@NgModule({
  declarations: [
    ...sharedComponents,
    ...pipes,
    ...directives
  ],
  imports: [
    CommonModule,
    OverlayModule,
    TranslateModule,
    ScrollingModule,
    ...sharedMaterialModules,
    ...sharedAngularModules
  ],
  exports: [
    ...sharedMaterialModules,
    ...sharedComponents,
    ...sharedAngularModules,
    ...pipes,
    ...directives
  ],
  providers: [
    ToastrService,
    { provide: DateAdapter, useClass: AppDateAdapter, deps: [MAT_DATE_LOCALE, Platform] },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ],
  entryComponents: [ConfirmActionComponent, DateFilterComponent]
})
export class SharedModule { }