import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DEFAULT_DATE_TIME_FORMAT } from '../config/app.config';

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe extends DatePipe implements PipeTransform {

  transform(value: Date, timezone?: string): any {
    return super.transform(value, DEFAULT_DATE_TIME_FORMAT, timezone);
  }
}
