import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../../core/user.service';
import { Roles } from '../enums/roles.enum';
import { hasRole } from '../helpers/has-role';

@Injectable({
  providedIn: 'root'
})
export class AdminWithSelectedCompanyGuard implements CanActivate {

  constructor(private router: Router, private userService: UserService) { }

  canActivate() {
    if (this.userService.isManagerLogged()) {
      if (hasRole([Roles.Manager])) {
        return true;
      } else if (hasRole([Roles.Admin])) {
        if (this.userService.temporaryCompany) {
          return true;
        } else {
          this.router.navigate(['/select-company']);
          return false;
        }
      }
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login']);
    return false;
  }

}
