import { Component, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IPhoto } from '../../declarations/photo';
import { PhotoType } from '../../enums/upload-photo-type.enum';
import { DEFAULT_LANG_KEY, LOCAL_STORAGE_LANG_KEY, MAX_UPLOAD_IMAGE_SIZE_IN_MB } from '../../config/app.config';
import { resolveLanguage } from '../../../shared/helpers/utils';

@Component({
  selector: 'app-upload-photos',
  templateUrl: './upload-photos.component.html',
  styleUrls: ['./upload-photos.component.scss']
})

export class UploadPhotosComponent {
  @Input() photos: IPhoto[];
  @Input() disabled: boolean;

  @Output() onRemovePhoto: EventEmitter<number> = new EventEmitter<number>();
  @Output() onAddPhoto: EventEmitter<IPhoto[]> = new EventEmitter<IPhoto[]>();
  @Output() isDirty: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() buttonText: string;
  @Input() type: PhotoType;
  photoTypeEnum = PhotoType;

  @ViewChild('fileUploadInput') fileUploadInput: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
  ) {}

  onImagesChange(event) {
    if (this.disabled) return;
    this.isDirty.emit(true);
    this.photos = [];
    let imageFile: File;

    if (event.target.files && event.target.files[0]) { // if user has selected an image
      imageFile = event.target.files[0];
    } else if (event.dataTransfer.files && event.dataTransfer.files[0]) { // if user has dragged-n-dropped an image
      imageFile = event.dataTransfer.files[0];
    } else {
      return false;
    }

    if (imageFile.type.substring(0, 5) !== 'image') {
      const errorMsg = new Map([
        ['bg', 'Моля, изберете валидно изображение от тип - *.jpg, *.png, *.gif, *.tif'],
        ['en', 'Please, select a valid image of type - *.jpg, *.png, *.gif, *.tif'],
        ['en', 'Vă rugăm să selectați o imagine validă de tip - *.jpg, *.png, *.gif, *.tif']
      ])
      alert(errorMsg.get(resolveLanguage()))
      return false;
    }

    const imageSizeInMb = parseFloat((imageFile.size / (1024*1024)).toFixed(2));

    if ( imageSizeInMb > MAX_UPLOAD_IMAGE_SIZE_IN_MB) {
      const errorMsg = new Map([
        ['bg', 'Моля, изберете изображение с размер до максимум 5 MB'],
        ['en', 'Please, select an image with a maximum size of 5 MB'],
        ['ro', 'Vă rugăm să selectați o imagine cu o dimensiune maximă de 5 MB']
      ])
      alert(errorMsg.get(resolveLanguage()));
      return false;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const url = window.URL.createObjectURL(imageFile);
      const newImage = {
        path: url,
        file: imageFile
      };
      this.photos.push(newImage);
      this.fileUploadInput.nativeElement.value = '';
      this.onAddPhoto.emit(this.photos);
    }
    reader.readAsDataURL(imageFile);

  }

  deleteImage(photo: IPhoto, index: number) {
    const confirmMsg = new Map([
      ['bg', 'Сигурни ли сте, че искате да изтриете снимката?'],
      ['en', 'Are you sure you want to delete the photo?'],
      ['ro', 'Sunteți sigur că doriți să ștergeți fotografia?']
    ])
    if (window.confirm(confirmMsg.get(resolveLanguage()))) {
      this.isDirty.emit(true);
      this.onRemovePhoto.emit(index);
      this.photos.splice(index, 1);
      return true;
    }
    return false;
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }
}
