import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { AdminWithSelectedCompanyGuard } from './shared/guards/admin-with-selected-company.guard';
import { AdminGuard } from './shared/guards/admin.guard';
import { SelectCompanyComponent } from './shared/components/select-company/select-company.component';
import { ForgottenPasswordComponent } from './modules/not-logged-in/forgotten-password/forgotten-password.component';
import { LoginComponent } from './modules/not-logged-in/login/login.component';
import { SignupComponent } from './modules/not-logged-in/signup/signup.component';
import { LoggedInRedirect } from './shared/guards/logged-in-redirect';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordComponent } from './modules/not-logged-in/change-password/change-password.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  { path: 'login', component: LoginComponent, canActivate: [LoggedInRedirect] },
  { path: 'register', component: SignupComponent },
  { path: 'forgotten-password', component: ForgottenPasswordComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  {
    path: 'general-information',
    loadChildren: () => import('./modules/general-information/general-information.module').then(m => m.GeneralInformationModule),
    canActivate: [AdminWithSelectedCompanyGuard]
  },
  {
    path: 'billing-information',
    loadChildren: () => import('./modules/billing-information/billing-information.module').then(m => m.BillingInformationModule),
    canActivate: [AdminWithSelectedCompanyGuard]
  },
  {
    path: 'account-settings',
    loadChildren: () => import('./modules/account-settings/account-settings.module').then(m => m.AccountSettingsModule),
    canActivate: [AdminWithSelectedCompanyGuard]
  },
  {
    path: 'inventory',
    loadChildren: () => import('./modules/inventory/inventory.module').then(m => m.InventoryModule),
    canActivate: [AdminWithSelectedCompanyGuard]
  },
  {
    path: 'select-company',
    component: SelectCompanyComponent,
  },
  {
    path: 'management',
    loadChildren: () => import('./modules/management/management.module').then(m => m.ManagementModule),
    canActivate: [AdminGuard]
  },
];

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })
  ],
  providers: [LoggedInRedirect]
})
export class AppRoutingModule { }
