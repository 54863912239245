import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app.routing';
import { LanguageInterceptor } from './interceptors/language.interceptor';
import { NgxsModule } from '@ngxs/store';
import { MatRadioModule } from '@angular/material/radio';
import { AppComponent } from './app.component';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavbarModule } from './shared/components/navbar/navbar.module';
import { FooterModule } from './shared/components/footer/footer.module';
import { AuthGuard } from './shared/guards/auth.guard';
import { UserService } from './core/user.service';
import { JwtInterceptor } from './shared/helpers/jwt.interceptor';
import { SharedModule } from './shared/shared.module';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from './shared/helpers/date.adapter';
import { TOASTR_DEFAULT_OPTIONS, DEFAULT_SPINNER_OPTIONS, DEFAULT_LOCALE, GTM_DASHBOARD_ID } from './shared/config/app.config';
import { OverlayModule } from '@angular/cdk/overlay';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS } from '@angular/material/progress-spinner';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AgmCoreModule } from '@agm/core';
import { CompanyService } from './core/company.service';
import { VoucherService } from './core/voucher.service';
import { ManagementService } from './modules/management/management.service';
import { environment } from '../environments/environment';
import { SettingsState } from './shared/state/settings.state';
import { NotLoggedInModule } from './modules/not-logged-in/not-logged-in.module';
import {NgrokInterceptor} from './interceptors/ngrok.interceptor';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const appModules = [
  FooterModule,
  SharedModule,
];

const angularModules = [
  BrowserModule,
  BrowserAnimationsModule,
  FormsModule,
  MatRadioModule,
  HttpClientModule,
  OverlayModule,
  NavbarModule,
  RouterModule,
  AppRoutingModule,
  HttpClientModule,
  ToastrModule.forRoot(TOASTR_DEFAULT_OPTIONS),
  NgbModule,
  AgmCoreModule.forRoot({
    apiKey: environment.GOOGLE_MAPS_API_KEY,
    libraries: ['places']
  }),
  MatGoogleMapsAutocompleteModule,
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    ...angularModules,
    ...appModules,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxsModule.forRoot([SettingsState], {
      developmentMode: !environment.isProduction
    })
  ],
  providers: [
    AuthGuard,
    HttpClientModule,
    DeviceDetectorService,
    UserService,
    CompanyService,
    VoucherService,
    ManagementService,
    NotLoggedInModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: NgrokInterceptor, multi: true},
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS, useValue: DEFAULT_SPINNER_OPTIONS },
    { provide: MAT_DATE_LOCALE, useValue: DEFAULT_LOCALE },
    { provide: 'googleTagManagerId', useValue: GTM_DASHBOARD_ID },
    HttpClient
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
