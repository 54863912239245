<div class="container-fluid">
  <div *ngIf="!isUser" class="row">
    <form [formGroup]="form" autocomplete="off" class="col-md-6 login-form">
      <div class="d-flex justify-content-center mb-5">
        <img src="assets/images/logo.png" class="logo" />
      </div>

      <mat-form-field appearance="standard">
        <mat-label>
          {{ 'CHOOSE_LANGUAGE' | translate }}
        </mat-label>
        <mat-select formControlName="locale">
          <mat-option value="bg">Български</mat-option>
          <mat-option value="en">English</mat-option>
          <mat-option value="ro">Română</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mb-3">
        <mat-label>
          {{ 'LOGIN_EMAIL' | translate}}
        </mat-label>
        <input type="email" formControlName="email" matInput />
      </mat-form-field>

      <mat-form-field>
        <mat-label>
          {{ 'LOGIN_PASSWORD' | translate}}
        </mat-label>
        <input type="password" formControlName="password" matInput />
      </mat-form-field>

      <div class="center-div">
        <button color="primary" (click)="login()">
          <mat-spinner *ngIf="loading" class="loading-spinner"></mat-spinner>
          <span>
            {{ 'LOGIN_SUBMIT' | translate}}</span>
        </button>
        <div class="text-center mt-3">
          <a routerLink="/forgotten-password" class="primary-text-color font-weight-bold">{{ 'FORGOTTEN_PASSWORD_QUESTION' | translate }}</a>
          <p class="mt-3 font-weight-bold">{{ 'DO_YOU_HAVE_ACCOUNT_QUESTION' | translate }} <a routerLink="/register" class="primary-text-color">{{ 'SIGNUP_NOW' | translate }}</a></p>
        </div>
      </div>
    </form>
    <div class="col-md-6 mt-5">
      <img src="assets/images/chef.png" alt="Вход" class="right-image">
    </div>
  </div>
  <div *ngIf="isUser && !isPassReset" class="email-success-msg-container">
    <h3 class="email-success-msg">
      {{ 'CONFIRM_USER_EMAIL_SUCCESS' | translate }}
    </h3>
    <mat-icon class="success-icon" color="primary">check_circle</mat-icon>
  </div>
  <div *ngIf="isUser && isPassReset" class="email-success-msg-container">
    <h3 class="email-success-msg">
      {{ 'CHANGE_PASSWORD_SUCCESS' | translate }} {{ 'GO_TO_APP' | translate }}
    </h3>
    <mat-icon class="success-icon" color="primary">check_circle</mat-icon>
  </div>
</div>