import { environment as env } from '../../../environments/environment';
import { UPLOADS_PRODUCTS_PATH, UPLOADS_RESTAURANTS_PATH } from '../config/app.config';
import { UploadType } from '../enums/upload-type.enum';
import { Store } from '@ngxs/store';
import { LOCAL_STORAGE_LANG_KEY } from '../config/app.config';
import { DEFAULT_LANG_KEY } from '../config/app.config';

export function createPhotoUrl(photoUrl: string, type: UploadType) {
  const path = type === UploadType.Product ? UPLOADS_PRODUCTS_PATH : UPLOADS_RESTAURANTS_PATH;
  return photoUrl ? { path: `${env.baseUrl}${path}/${photoUrl}`, isSavedInBackend: true, file: photoUrl } : null;
}

export const localeLanguages = ['en', 'bg', 'ro'];

export const phoneRegex = /(\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/;

export const decimalRegex = /^\s*-?[1-9]\d*(\.\d{1,2})?\s*$/;

export function isMobileDevice(): boolean {
  const media = window.matchMedia('(max-width: 768px)');
  return media.matches;
}

export function addTimeToDate(inputDate: Date, inputTime: string): Date {
  const date = new Date(inputDate);
  const time = inputTime.split(':');

  const hours = parseInt(time[0], 10);
  const minutes = parseInt(time[1], 10);

  date.setHours(hours, minutes, 0, 0);
  return date;
}

export function getExtension(fileName: string): string {
  return fileName ? fileName.split('.').pop() : '';
}

export function createpickUpHoursArray() {
  const hours = [];
  for (let index = 0; index < 24; index++) {
    hours.push({
      value: index,
      display: `${index}:00`
    }, {
      value: (index + 0.5),
      display: `${index}:30`
    });
  }

  return hours;
}



export function formatDateFilter(date: Date): string {
  date = new Date(date);
  // date.setHours(0, 0, 0, 0);
  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate() - 1} 21:00:00`;
}

export function resolveLanguage() {
  return localStorage.getItem(LOCAL_STORAGE_LANG_KEY) || DEFAULT_LANG_KEY
}

export function validateCompanyDataAndReturnErrors(store: Store) {

  const restaurants = store.selectSnapshot((state) => state.settings.restaurants)
  const products = store.selectSnapshot((state) => state.settings.products);
  const company = store.selectSnapshot((state) => state.settings.company);

  const restaurantErrors = [];
  const productErrors = [];
  const companyErrors = [];
  const allErrors = [];

  restaurants.forEach((restaurant) => {
    if (restaurant.longitude === 0) {
      restaurantErrors.push(`${restaurant.name} is missing "longitude"`);
    }
    if (restaurant.latitude === 0) {
      restaurantErrors.push(`${restaurant.name} is missing "latitude"`);
    }
    if (!restaurant.phoneNumber) {
      restaurantErrors.push(`${restaurant.name} is missing "phone number"`);
    }
    if (!restaurant.address) {
      restaurantErrors.push(`${restaurant.name} is missing "address"`);
    }
    if (!restaurant.summary) {
      restaurantErrors.push(`${restaurant.name} is missing "summary"`);
    }
    if (!restaurant.timezone) {
      restaurantErrors.push(`${restaurant.name} is missing "timezone"`);
    }
    if (!restaurant.photoAvatar) {
      restaurantErrors.push(`${restaurant.name} is missing "photo avatar"`);
    }
    if (!restaurant.photoCover) {
      restaurantErrors.push(`${restaurant.name} is missing "photo cover"`);
    }
    if (!restaurant.emails) {
      restaurantErrors.push(`${restaurant.name} is missing "email/emails"`);
    }
  });

  products.forEach((product) => {
    if (!product.currency) {
      productErrors.push(`${product.name} is missing "currency"`);
    }
    if (!product.photo) {
      productErrors.push(`${product.name} is missing "photo"`);
    }
    if (!product.summary) {
      productErrors.push(`${product.name} is missing "summary"`);
    }
    if (!product.price) {
      productErrors.push(`${product.name} is missing "price"`);
    }
  });

  if (!company.name) {
    companyErrors.push(`Company name is missing or "null"`);
  }
  if (!company.email) {
    companyErrors.push(`${company.name} is missing "email"`);
  }
  if (!company.eik) {
    companyErrors.push(`${company.name} is missing "eik"`);
  }
  if (!company.vatNumber) {
    companyErrors.push(`${company.name} is missing "vat number"`);
  }
  if (!company.phoneNumber) {
    companyErrors.push(`${company.name} is missing "phone number"`);
  }
  if (!company.billingAddress) {
    companyErrors.push(`${company.name} is missing "billing address"`);
  }

  if (restaurantErrors.length === 0 && productErrors.length === 0 && companyErrors.length === 0) {
    return null;
  } else {
    if (companyErrors.length > 0) {
      companyErrors.unshift('Company Errors:');
    }
    if (restaurantErrors.length > 0) {
      restaurantErrors.unshift('Restaurant(s) Errors:');
    }
    if (productErrors.length > 0) {
      productErrors.unshift('Product(s) Errors:');
    }
    return allErrors.concat(companyErrors, restaurantErrors, productErrors);
  }
}
