export enum OrderStatus {
  Booked = 0,
  Confirmed = 1,
  Canceled = 2,
  PreBooked = 3,
  PaymentFailed = 4,
  CapturingPayment = 5
}

export const ORDER_STATUSES_DICTIONARY = {
  [OrderStatus.Booked]: 'ORDER_STATUS_RESERVED',
  [OrderStatus.Confirmed]: 'ORDER_STATUS_SOLD',
  [OrderStatus.Canceled]: 'ORDER_STATUS_CANCELED',
  [OrderStatus.PreBooked]: 'ORDER_STATUS_PREBOOKED',
  [OrderStatus.PaymentFailed]: 'ORDER_STATUS_PAYMENT_FAILED',
  [OrderStatus.CapturingPayment]: 'ORDER_STATUS_CAPTURING_PAYMENT',
}

export const CSS_CLASSES_DICTIONARY = {
  [OrderStatus.Booked]: 'order-reserved',
  [OrderStatus.Confirmed]: 'order-sold',
  [OrderStatus.Canceled]: 'order-canceled',
  [OrderStatus.PreBooked]: 'order-reserved',
  [OrderStatus.PaymentFailed]: 'order-canceled',
  [OrderStatus.CapturingPayment]: 'order-reserved',
}
