import {
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { TIMEZONES_LIST } from '../config/constants';
import { IBusiness } from '../declarations/company';

export const EmailValidation = [
  Validators.required,
  Validators.email,
  Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
  Validators.maxLength(100)
];

export const PasswordValidation = [
  Validators.required,
  Validators.minLength(8)
];

export class RepeatPasswordStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return (
      control &&
      control.parent.get('password').value !==
      control.parent.get('passwordAgain').value &&
      control.dirty
    );
  }
}
export function RepeatPasswordValidator(group: FormGroup) {
  const { password, passwordAgain } = group.value;
  const error = (password === passwordAgain) ? null
    : { passwordsNotEqual: true };

  group.controls.passwordAgain.setErrors(error);
  return error;

}

export function AgreeWithTermsValidator(control: FormControl) {
  return control.value
    ? null
    : { notAgreeWithTerms: true };
}

export function IntegersOnly(control: FormControl) {
  const value = control.value;
  return value && Number.isInteger(value)
    ? null
    : { notIntegerNumber: true };
}

export function validatePickUpTime(pickUpFrom: number, pickUpTo: number): boolean {
  if (pickUpTo === 0 && pickUpTo !== pickUpFrom) pickUpTo = 24;
  return pickUpFrom + 0.5 <= pickUpTo;
}

export function validateTimeZoneAutocomplete(control: FormControl): any {
  const { value } = control;
  return TIMEZONES_LIST.indexOf(value) === - 1 ? { notValidTimezone: true } : null;
}
