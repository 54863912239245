<ng-container *ngIf="sideMenuIsMobile">
  <div class="w-100">
    <button id="menu-close-btn" mat-icon-button class="float-right" (click)="inputSideNav.toggle()">
      <mat-icon *ngIf="inputSideNav.opened">
        close
      </mat-icon>
    </button>
  </div>
</ng-container>
<div class="sidebar-logo" [class.mobile-menu]="sideMenuIsMobile">
  <img src="assets/images/logo.png" />
</div>
<ng-container *appHasRole="[roles.Admin]">
  <div class="company-select-menu">
    <mat-form-field>
      <mat-label>{{'INSERT_PLACE_NAME' | translate}}</mat-label>
      <input type="text" placeholder="{{'INSERT_PLACE_NAME' | translate}}" matInput [formControl]="business" [matAutocomplete]="auto">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getOptionText" (optionSelected)="onBusinessSelect($event.option.value)">
        <cdk-virtual-scroll-viewport itemSize="20" class="virtual-scroll-viewport">
          <mat-option *cdkVirtualFor="let option of (filteredOptions | async)" [value]="option" class="virtual-item">
            {{option.name}}
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <ng-container *ngIf="sideMenuIsMobile && isAdmin && !!isCompanySelected">
    <ng-container *ngIf="selectedCompany.isActive">
      <button type="button" class="disable-account-button" mat-raised-button color="accent" (click)="disableAccount()">{{'BLOCK_ACCOUNT' | translate}}</button>
    </ng-container>
    <ng-container *ngIf="!selectedCompany.isActive">
      <button type="button" class="disable-account-button" mat-raised-button color="primary" (click)="activateAccount()">{{'ACTIVATE_ACCOUNT' | translate}}</button>
    </ng-container>
  </ng-container>
</ng-container>
<div class="sidebar-menu" (click)="closeMenuOnClick()">
  <ng-container *appHasRole="[roles.Admin]">
    <div class="menu-item" routerLink="/management" routerLinkActive="active-menu">
      <mat-icon>manage_accounts</mat-icon>
      <span>{{'MANAGE' | translate}}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="!isAdmin || (isAdmin && isCompanySelected)">
    <div class="menu-item" routerLink="/general-information" routerLinkActive="active-menu">
      <mat-icon>info</mat-icon>
      <span>{{'MY_PROFILE' | translate}}</span>
    </div>
    <div class="menu-item" routerLink="/billing-information" routerLinkActive="active-menu">
      <mat-icon>account_balance_wallet</mat-icon>
      <span>{{'COMPANY_DATA' | translate}}</span>
    </div>
    <div class="menu-item" routerLink="/account-settings" routerLinkActive="active-menu">
      <mat-icon>settings</mat-icon>
      <span>{{'MY_PLACE' | translate}}</span>
    </div>

    <div class="menu-item" routerLink="/inventory" routerLinkActive="active-menu" [routerLinkActiveOptions]="{exact: true}">
      <mat-icon>outbox</mat-icon>
      <span>{{'MY_BOX' | translate}}</span>
    </div>
  </ng-container>
</div>
<div class="sidebar-menu">
  <div class="menu-item ml-2">
    <select class="custom-select" (change)="changeLang($event.target.value)">
      <option value="bg" [selected]="selectedLanguage === 'bg'">Български</option>
      <option value="en" [selected]="selectedLanguage === 'en'">English</option>
      <option value="ro" [selected]="selectedLanguage === 'ro'">Română</option>
    </select>
  </div>
</div>
<div class="sidebar-menu secondary-menu" [class.mobile-menu]="sideMenuIsMobile" (click)="closeMenuOnClick()">

  <!-- <ng-container *ngIf="(!isAdmin && sideMenuIsMobile) || (isAdmin && !!isCompanySelected)">
    <div class="menu-item" routerLink="/financials/profits" routerLinkActive="active-menu" *ngIf="sideMenuIsMobile" [routerLinkActiveOptions]="{exact: true}">
      <mat-icon>paid</mat-icon>
      <span>{{'INCOME' | translate}}</span>
    </div>
  </ng-container> -->

  <ng-container *ngIf="!isAdmin && sideMenuIsMobile">
    <div class="menu-item" routerLink="/inventory/verify-order" routerLinkActive="active-menu" [routerLinkActiveOptions]="{exact: true}">
      <mat-icon>history</mat-icon>
      <span>{{'PIN' | translate}} {{'CODE' | translate}}</span>
    </div>
  </ng-container>

  <ng-container *ngIf="(!isAdmin && sideMenuIsMobile) || (isAdmin && !!isCompanySelected)">
    <div class="menu-item" routerLink="/inventory/order-history" routerLinkActive="active-menu" [routerLinkActiveOptions]="{exact: true}">
      <mat-icon>check_circle</mat-icon>
      <span>{{'FINISHED_ORDERS' | translate}}</span>
    </div>
  </ng-container>
</div>

<div class="sidebar-menu secondary-menu" [class.mobile-menu]="sideMenuIsMobile" (click)="closeMenuOnClick()">
  <ng-container *appHasRole="[roles.Manager]">
    <div class="menu-item ml-2">
      <mat-icon>fact_check</mat-icon>
      <a href="{{'TERMS_OF_USE_LINKS' | translate}}" target="_blank"><span>{{'TERMS_OF_USE' | translate}}</span></a>
    </div>
    <div class="menu-item ml-2">
      <mat-icon>mail_outline</mat-icon>
      <a href="{{environment.contact_mail}}" target="_blank"><span>{{'CONTACTS' | translate}}</span></a>
    </div>
  </ng-container>
  <div class="menu-item ml-2">
    <mat-icon>exit_to_app</mat-icon>
    <a class="navbar-menu-item" (click)="logout()">
      {{'EXIT' | translate}}
    </a>
  </div>
</div>
