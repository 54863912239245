import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { CSS_CLASSES_DICTIONARY, OrderStatus, ORDER_STATUSES_DICTIONARY } from '../enums/order-status.enum';

@Pipe({
  name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private cd: ChangeDetectorRef
  ) {

  }
  transform(status: OrderStatus): string {
    const translatePipe = new TranslatePipe(this.translateService, this.cd);
    return `<span class="order-status-pill ${CSS_CLASSES_DICTIONARY[status]}"></span>${translatePipe.transform(ORDER_STATUSES_DICTIONARY[status])}`;
  }

}
