import { NativeDateAdapter, MatDateFormats } from '@angular/material/core';
import moment from 'moment';
import { Injectable } from "@angular/core";

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {

  private separatorForUI = '/';
  private allowedSeparators = ['/', '-', '.'];

  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      const year = date.getFullYear();
      return `${day}${this.separatorForUI}${month}${this.separatorForUI}${year}`;
    }
    return date.toDateString();
  }
  parse(value: any): Date | null {
    if (value
      && value.constructor
      && value.constructor.name === String.name
    ) {
      const usedSeparator = this.determineSeparatorAndReturn(value);
      const splits = value.split(usedSeparator);

      if (splits.length === 3) {
        const day = Number(splits[0]);
        const month = Number(splits[1]);
        const year = Number(splits[2]);
        if (moment(`${day}${this.separatorForUI}${month}${this.separatorForUI}${year}`, 'D/M/YYYY', true).isValid()) {
          return new Date(year, month - 1, day);
        }
      }
    }

    return super.parse(value);
  }

  determineSeparatorAndReturn(dateInput: string) {
    for (const separator of this.allowedSeparators) {
      if (dateInput.includes(separator)) {
        return separator;
      }
    }
  }
}
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};
