<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 signup-form">
      <div class="d-flex justify-content-center mb-5">
        <img src="assets/images/logo.png" class="logo" />
      </div>
      <h2>
        {{ 'SIGNUP_HEADING' | translate }}
      </h2>
      <mat-vertical-stepper linear #stepper>
        <mat-step [stepControl]="firstFormGroup">
          <form [formGroup]="firstFormGroup" autocomplete="off" class="mt-3">
            <ng-template matStepLabel>
              {{ 'SIGNUP_STEP_1' | translate }}
            </ng-template>

            <mat-form-field appearance="standard">
              <mat-label>
                {{ 'CHOOSE_LANGUAGE' | translate }}
              </mat-label>
              <mat-select formControlName="locale">
                <mat-option value="bg">Български</mat-option>
                <mat-option value="en">English</mat-option>
                <mat-option value="ro">Română</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="mb-3 w-100">
              <mat-label>
                {{ 'SIGNUP_PLACE_NAME' | translate }}
              </mat-label>
              <input type="text" formControlName="companyName" matInput />
              <mat-error *ngIf="!firstFormGroup.get('companyName').valid">
                {{ 'ERROR_COMPANY_NAME' | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field class="mb-3 w-100">
              <mat-label>
                {{ 'SIGNUP_EMAIL' | translate }}
              </mat-label>
              <input type="email" formControlName="email" matInput />
              <mat-error *ngIf="!firstFormGroup.get('email').valid">
                {{ 'ERROR_EMAIL' | translate }}
              </mat-error>

            </mat-form-field>
            <mat-form-field class="mb-3 w-100">
              <mat-label>
                {{ 'SIGNUP_PASSWORD' | translate }}
              </mat-label>
              <input type="password" formControlName="password" matInput />
              <mat-error *ngIf="!firstFormGroup.get('password').valid">
                {{ 'ERROR_PASSWORD_LENGTH' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="mb-3 w-100">
              <mat-label>
                {{ 'SIGNUP_PASSWORD_VERIFY' | translate }}
              </mat-label>
              <input type="password" formControlName="passwordAgain" matInput />
              <mat-error *ngIf="firstFormGroup.get('passwordAgain').hasError('passwordsNotEqual')">
                {{ 'ERROR_PASSWORD_MATCH' | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field class="mb-3 w-100">
              <mat-label>
                {{ 'SIGNUP_PROMO_CODE' | translate }}
              </mat-label>
              <input type="text" formControlName="promoCode" matInput />
              <mat-error *ngIf="!firstFormGroup.get('promoCode').valid">{{promoCodeError}}</mat-error>
            </mat-form-field>
            <button mat-button [disabled]="!firstFormGroup.valid || firstFormGroup.pristine" (click)="stepper.next()">{{ 'NEXT_BUTTON_LABEL' | translate }}</button>
          </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup" [editable]="true">
          <ng-template matStepLabel>
            {{ 'SIGNUP_STEP_2' | translate }}
          </ng-template>
          <form [formGroup]="secondFormGroup" autocomplete="off" class="mt-3">
            <mat-form-field class="mb-3 w-100">
              <mat-label>
                {{ 'SIGNUP_FIRST_NAME' | translate }}
              </mat-label>
              <input type="text" formControlName="firstName" matInput />
              <mat-error *ngIf="!secondFormGroup.get('firstName').valid">
                {{ 'ERROR_FIRST_NAME' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="mb-3 w-100">
              <mat-label>
                {{ 'SIGNUP_LAST_NAME' | translate }}
              </mat-label>
              <input type="text" formControlName="lastName" matInput />
              <mat-error *ngIf="!secondFormGroup.get('lastName').valid">
                {{ 'ERROR_LAST_NAME' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="mb-3 w-100">
              <mat-label>
                {{ 'SIGNUP_PHONE_NUMBER' | translate }}
              </mat-label>
              <input type="tel" formControlName="phoneNumber" matInput />
              <mat-hint>
                {{ 'PHONE_FORMAT_MESSAGE' | translate }}
              </mat-hint>
              <mat-error *ngIf="!secondFormGroup.get('phoneNumber').valid">
                {{ 'PHONE_FORMAT_MESSAGE' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="mb-3 w-100">
              <mat-label>
                {{ 'SIGNUP_EIN' | translate }}
              </mat-label>
              <input formControlName="ein" matInput />
              <mat-hint>
                {{ 'EIN_FORMAT_MESSAGE' | translate }}
              </mat-hint>
              <mat-error *ngIf="!secondFormGroup.get('ein').valid">
                {{ 'EIN_WRONG' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="mb-3 w-100">
              <mat-label>{{'PLACE_ADDRESS' | translate}}</mat-label>
              <input type="text" matInput formControlName="address" [value]="addressValue" matGoogleMapsAutocomplete (onAutocompleteSelected)="onAutocompleteSelected($event)" (onLocationSelected)="onLocationSelected($event)">
              <mat-error *ngIf="!secondFormGroup.get('address').valid">
                {{ 'ERROR_ADDRESS' | translate }}
              </mat-error>

            </mat-form-field>
            <button mat-button [disabled]="!firstFormGroup.valid || firstFormGroup.pristine" (click)="stepper.next()">{{ 'NEXT_BUTTON_LABEL' | translate }}</button>
          </form>
        </mat-step>
        <mat-step [stepControl]="thirdFormGroup" [editable]="true">
          <ng-template matStepLabel>
            {{ 'SIGNUP_STEP_3' | translate }}
          </ng-template>
          <h2 class="mt-3">
            {{ 'SIGNUP_STEP_3_HEADING' | translate }}
          </h2>
          <form [formGroup]="thirdFormGroup" autocomplete="off">
            <div class="row">
              <div *ngFor="let businessType of businessTypesList" class="col-md-6 mb-1">
                <div class="business-type" [class.selected-business-type]="businessType.value === selectedBusinessType" (click)="selectBusinessType(businessType)">
                  <img [src]="'assets/images/business-type/' + businessType.image" [attr.alt]="businessType.name | translate">
                  <span class="business-label">{{businessType.name | translate}}</span>
                </div>
              </div>
              <span *ngIf="!thirdFormGroup.get('businessType').valid" class="col-md-12 mt-3 tc-error">
                {{ 'ERROR_BUSINESS_TYPE' | translate }}</span>

            </div>
            <mat-checkbox formControlName="agreeWithTerms" class="font-weight-bold mt-3" color="primary">
              {{ 'SIGNUP_AGREE_WITH' | translate }} <a href="{{environment.terms_and_conditions_link[locale]}}" target="_blank" class="primary-text-color">
                {{ 'SIGNUP_TC' | translate }}</a>
            </mat-checkbox>
            <span *ngIf="!thirdFormGroup.get('agreeWithTerms').valid" class="tc-error">
              {{ 'ERROR_AGREE_WITH_TERMS' | translate }}</span>
          </form>
        </mat-step>
      </mat-vertical-stepper>

      <div class="center-div mt-5">
        <button mat-button type="button" (click)="onSubmit()" [disabled]="disableSubmit">
          <mat-spinner *ngIf="loading" class="loading-spinner"></mat-spinner>
          <span>{{'SIGNUP_SUBMIT' | translate}}</span>
        </button>
        <div class="text-center mt-3">
          <p class="mt-3 font-weight-bold">{{'ALREADY_SIGNED_IN_LABEL' | translate}} <a routerLink="/login" class="primary-text-color">{{'LOGIN_LINK_LABEL' | translate}}</a></p>
        </div>
      </div>
    </div>
    <div [ngSwitch]="locale" class="col-md-6 mt-5">
      <img *ngSwitchCase="'bg'" src="assets/images/BG.svg" alt="Регистрация" class="right-image">
      <img *ngSwitchCase="'en'" src="assets/images/EN.svg" alt="Регистрация" class="right-image">
      <img *ngSwitchCase="'ro'" src="assets/images/EN.svg" alt="Регистрация" class="right-image">
      <img *ngSwitchDefault src="assets/images/signup-business-en.png" alt="Регистрация" class="right-image">
    </div>
  </div>
</div>
