import {Injectable} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root'
})

export class SidenavService {

  private sidenav: MatSidenav;

  public set(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  public setToMobile() {
    this.sidenav.mode = 'over';
  }

  public isMobile() {
    return this.sidenav && this.sidenav.mode === 'over';
  }

  public setToDesktop() {
    this.sidenav.mode = 'side';
  }

  public open() {
    return this.sidenav.open();
  }

  public close() {
    return this.sidenav.close();
  }

  public toggle(): void {
    this.sidenav.toggle();
  }
}
