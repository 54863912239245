import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ManagementConsoleTabs } from '../../shared/enums/management-console-tabs.enum';

@Injectable({
  providedIn: 'root'
})
export class ManagementService {

  tab: BehaviorSubject<ManagementConsoleTabs>;
  voucherCode: BehaviorSubject<string>;

  constructor() {
    this.tab = new BehaviorSubject(null);
    this.voucherCode = new BehaviorSubject(null);
  }

  switchToTab(tab: ManagementConsoleTabs): void {
    this.tab.next(tab);
  }

  setVoucherCode(voucherCode: string): void {
    this.voucherCode.next(voucherCode);
  }
}
