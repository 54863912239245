
<ng-container *ngIf="isManagerLogged">
  <mat-sidenav-container *ngIf="isManagerLogged" class="navigation-container">
    <mat-sidenav #sidenav position="start" role="navigation" class="menu-width" mode="side" [autoFocus]="false"
      [disableClose]="!sideMenuIsMobile">
      <app-sidebar [inputSideNav]="sidenav"></app-sidebar>
    </mat-sidenav>

    <mat-sidenav-content>
      <app-navbar [inputSideNav]="sidenav"></app-navbar>
      <div class="main-content" [class.greater-height]="!userIsOnInventoryRoute || sideMenuIsMobile">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
<ng-container *ngIf="!isManagerLogged">
  <router-outlet></router-outlet>
</ng-container>