// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

const baseUrl = 'https://api.foodobox.com/';


export const environment = {
  environment: 'prod', // dev, qa, prod
  // @ts-ignore - will be substituted by setenv
  isProduction: true,
  apiV2Url: `${baseUrl}apiv2`,
  html_title: "Foodobox",
  terms_and_conditions_link: {
    bg: 'https://foodobox.com/terms-of-service.html',
    ro: 'https://foodobox.com/terms-of-service-ro.html',
    en: "https://foodobox.com/terms-of-service-en.html"
  },
  contact_mail: 'mailto:info@foodobox.com',
  baseUrl: baseUrl,
  GOOGLE_MAPS_API_KEY: 'AIzaSyA5ahGCvrL5E7hVXLwKC7Ai88s6e5nlIyE'
};
