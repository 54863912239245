import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { Subscription, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { UserService } from '../../../core/user.service';
import { validateCompanyDataAndReturnErrors, isMobileDevice } from '../../../shared/helpers/utils';
import { Roles } from '../../../shared/enums/roles.enum';
import { CompanyService } from '../../../core/company.service';
import { hasRole } from '../../../shared/helpers/has-role';
import { IRestaurant } from '../../../shared/declarations/restaurant';
import { IBusiness } from '../../../shared/declarations/company';
import { MatSidenav } from '@angular/material/sidenav';
import { ConfirmActionComponent } from '../../../shared/components/confirm-action/confirm-action.component';
import { DEFAULT_ERROR_HEADING, DEFAULT_ERROR_MESSAGE, DEFAULT_LANG_KEY, DEFAULT_MODAL_OPTIONS, DEFAULT_MODAL_SMALL_WIDTH, DEFAULT_SUCCESS_HEADING, LOCAL_STORAGE_LANG_KEY } from '../../../shared/config/app.config';
import { ToastrService } from 'ngx-toastr';
import { resolveLanguage } from '../../../shared/helpers/utils';
import { Store } from '@ngxs/store';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sidebar',
  styleUrls: ['./sidebar.component.scss'],
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  @Input() inputSideNav: MatSidenav;
  menuItems: any[];
  roles = Roles;
  restaurantsList: IRestaurant[];
  businessesList: IBusiness[];
  business: FormControl;
  filteredOptions: Observable<IRestaurant[]>;
  selectedLanguage: string;
  environment = environment;

  constructor(
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly toastrService: ToastrService,
    private readonly userService: UserService,
    private readonly companyService: CompanyService,
    private readonly translateService: TranslateService,
    private readonly store: Store
  ) {
    this.business = new FormControl();
    this.businessesList = [];
    this.restaurantsList = [];
    this.translateService.use(localStorage.getItem(LOCAL_STORAGE_LANG_KEY) || DEFAULT_LANG_KEY);
    this.selectedLanguage = localStorage.getItem(LOCAL_STORAGE_LANG_KEY) || DEFAULT_LANG_KEY
  }

  ngOnInit() {
    if (this.isAdmin) {
      this.loadData();
      this.subscriptions.add(this.companyService.onUpdateRestaurant.subscribe(result => {
        if (result) {
          this.loadData();
        }
      }));
    }
  }

  changeLang(language: string): void {
    localStorage.setItem(LOCAL_STORAGE_LANG_KEY, language);
    this.subscriptions.add(this.companyService.updateManagerLocale(language).subscribe(() => {
      this.selectedLanguage = language;
      window.location.reload();
    }, (err) => {
      this.toastrService.error(err.error.message ? err.error.message : DEFAULT_ERROR_MESSAGE, DEFAULT_ERROR_HEADING);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  logout(): void {
    this.userService.logout();
    window.location.href = '/login';
  }

  get isMobile(): boolean {
    return isMobileDevice();
  }

  get isAdmin(): boolean {
    return hasRole([Roles.Admin]);
  }

  get isCompanySelected(): boolean {
    return !!this.userService.temporaryCompany;
  }

  get selectedCompany(): IBusiness {
    return this.userService.temporaryCompany;
  }

  get sideMenuIsMobile(): boolean {
    return this.inputSideNav.mode === 'over';
  }

  onBusinessSelect(restaurant: IRestaurant) {
    const company = this.businessesList.find((business: IBusiness) => business.id === restaurant.companyId);
    this.userService.setTemporaryCompany(company);
    this.router.navigate(['/billing-information']);
  }

  activateAccount(): void {

    const errors = validateCompanyDataAndReturnErrors(this.store);

    if (errors) {
      alert(`Missing fields: \n ${[...errors].join('\n')}`)
    }
    const dialogRef = this.dialog.open(ConfirmActionComponent, {
      ...DEFAULT_MODAL_OPTIONS,
      width: DEFAULT_MODAL_SMALL_WIDTH
    });

    this.subscriptions.add(dialogRef.afterClosed().subscribe((action: boolean) => {
      if (action) {
        this.subscriptions.add(this.companyService.activateCompany(this.selectedCompany.id).subscribe(() => {
          const successMsg = new Map([
            ['bg', 'Потребителят е активиран успешно'],
            ['en', 'User activated successfully'],
            ['ro', 'Utilizatorul a fost activat cu succes']
          ])
          this.toastrService.success(successMsg.get(resolveLanguage()), DEFAULT_SUCCESS_HEADING);
          this.userService.setTemporaryCompany({
            ...this.selectedCompany,
            isActive: true
          });
        }, () => {
          const errorMsg = new Map([
            ['bg', 'Моля опитайте пак'],
            ['en', 'Please try again'],
            ['ro', 'Vă rugăm să încercați din nou']
          ])
          this.toastrService.error(errorMsg.get(resolveLanguage()), DEFAULT_ERROR_HEADING);
        }));
      }
    }));
  }

  disableAccount(): void {
    const dialogRef = this.dialog.open(ConfirmActionComponent, {
      ...DEFAULT_MODAL_OPTIONS,
      width: DEFAULT_MODAL_SMALL_WIDTH
    });

    this.subscriptions.add(dialogRef.afterClosed().subscribe((action: boolean) => {
      if (action) {
        this.subscriptions.add(this.companyService.disableCompany(this.selectedCompany.id).subscribe(() => {
          const successMsg = new Map([
            ['bg', 'Потребителят е деактивиран успешно'],
            ['en', 'User deactivated successfully'],
            ['ro', 'Utilizatorul a fost dezactivat cu succes']
          ])
          this.toastrService.success(successMsg.get(resolveLanguage()), DEFAULT_SUCCESS_HEADING);
          this.userService.setTemporaryCompany({
            ...this.selectedCompany,
            isActive: false
          });
        }, () => {
          const errorMsg = new Map([
            ['bg', 'Моля опитайте пак'],
            ['en', 'Please try again'],
            ['ro', 'Vă rugăm să încercați din nou']
          ])
          this.toastrService.error(errorMsg.get(resolveLanguage()), DEFAULT_ERROR_HEADING);
        }));
      }
    }));
  }

  getOptionText(option): string {
    return option && option.name ? option.name : '';
  }

  private loadData(): void {
    this.subscriptions.add(this.companyService.listStores().subscribe(({ restaurants }) => {
      this.restaurantsList = restaurants || [];

      this.filteredOptions = this.business.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    }));
    this.subscriptions.add(this.companyService.listCompanies().subscribe(({ companies }) => {
      this.businessesList = companies || [];
    }));
  }

  closeMenuOnClick(): void {
    if (this.sideMenuIsMobile) {
      this.inputSideNav.close();
    }
  }

  private _filter(value: any): IRestaurant[] {
    if (value.id) {
      return [];
    }
    const filterValue = value.toLowerCase();
    const foundItems = this.restaurantsList.filter((option: IRestaurant) => (option.name || '').toLowerCase().indexOf(filterValue) > -1);
    return foundItems || this.restaurantsList;
  }
}
