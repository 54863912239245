import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { LOCAL_STORAGE_USER_KEY } from '../config/app.config';
import { Roles } from '../enums/roles.enum';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit {
  // the role the user must have
  @Input() appHasRole: Roles[];

  isVisible = false;

  /**
   * @param {ViewContainerRef} viewContainerRef
   * 	-- the location where we need to render the templateRef
   * @param {TemplateRef<any>} templateRef
   *   -- the templateRef to be potentially rendered
   * @param {RolesService} rolesService
   *   -- will give us access to the roles a user has
   */
  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) { }

  ngOnInit() {
    //  We subscribe to the roles$ to know the roles the user has
    // If he doesn't have any roles, we clear the viewContainerRef
    try {

      const currentUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
      const userRole = currentUser.role;

      if (!userRole) {
        this.viewContainerRef.clear();
      }
      // If the user has the role needed to
      // render this component we can add it
      if (this.appHasRole.indexOf(userRole) > -1) {
        // If it is already visible (which can happen if
        // his roles changed) we do not need to add it a second time
        if (!this.isVisible) {
          // We update the `isVisible` property and add the
          // templateRef to the view using the
          // 'createEmbeddedView' method of the viewContainerRef
          this.isVisible = true;
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      } else {
        // If the user does not have the role,
        // we update the `isVisible` property and clear
        // the contents of the viewContainerRef
        this.isVisible = false;
        this.viewContainerRef.clear();
      }
    } catch (error) { }
  }

}
