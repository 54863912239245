import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment, environment as env} from '../../environments/environment';
import {COOKIE_TOKEN_KEY, LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_USER_KEY} from '../shared/config/app.config';
import {IManager} from '../shared/declarations/user-types';
import {IBusiness} from '../shared/declarations/company';
import {IRestaurant} from '../shared/declarations/restaurant';
import {map} from 'rxjs/operators';
import {Roles} from '../shared/enums/roles.enum';

const deleteCookie = (cname) => {
  document.cookie = cname + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
interface IPromoCodeDto {
  locale: string;
  promoCode: string;
}
interface IForgottenPasswordDto {
  locale: string;
  email: string;
}
interface IResetPasswordDto {
  locale: string;
  password: string;
  confirmPassword: string;
  token: string;
}
interface IConfirmEmailDto {
  registrationActivationToken: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  manager: BehaviorSubject<IManager>;
  onTemporaryCompanyChange: BehaviorSubject<IBusiness>;
  temporaryCompany: IBusiness;
  onSelectedResturantChange: BehaviorSubject<IRestaurant>;
  selectedResturant: IRestaurant;

  constructor(private http: HttpClient) {
    this.manager = new BehaviorSubject(null);
    this.onTemporaryCompanyChange = new BehaviorSubject(null);
    this.onSelectedResturantChange = new BehaviorSubject(null);
  }

  getManagerCompanyId(): number {
    const manager = this.getLoggedManager();

    if (!manager) {
      throw 'no manager';
    }

    if (manager.role === Roles.Admin && this.temporaryCompany) {
      return this.temporaryCompany.id;
    }

    return manager.companyId;
  }

  getLoggedManager(): IManager {
    const currentManager = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
    if (currentManager) {
      this.setLoggedManager(currentManager);
      return currentManager;
    }
    return null;
  }

  setTemporaryCompany(company: IBusiness): void {
    this.temporaryCompany = company;
    this.onTemporaryCompanyChange.next(company);
  }

  setSelectedResturant(restaurant: IRestaurant): void {
    this.selectedResturant = restaurant;
    this.onSelectedResturantChange.next(restaurant);
  }

  setLoggedManager(manager: IManager): void {
    this.manager.next(manager);
  }

  isManagerLogged(): boolean {
    const currentManager = this.getLoggedManager();
    return !!currentManager;
  }

  logout() {
    localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    deleteCookie(COOKIE_TOKEN_KEY);
  }

  getUserData(id: number): Observable<any> {
    return this.http.post(`${env.apiV2Url}/admin/user/find-user-by-id`, {id: id}).pipe(
      map((result: { data: any }) => {
        return result.data;
      })
    );
  }

  getUserOrders(id: number): Observable<any> {
    return this.http.post(`${env.apiV2Url}/admin/order/find-by-user-id`, {id: id}).pipe(
      map((result: { data: any }) => {
        return result.data;
      })
    );
  }

  activateUserMobile(id: number): Observable<any> {
    return this.http.post(`${env.apiV2Url}/admin/user/activate-user`, {id: id});
  }

  deactivateUserMobile(id: number): Observable<any> {
    return this.http.post(`${env.apiV2Url}/admin/user/deactivate-user`, {id: id});
  }

  blockUser(id: number): Observable<any> {
    return this.http.post(`${env.apiV2Url}/admin/user/block-user`, {id: id});
  }

  unblockUser(id: number): Observable<any> {
    return this.http.post(`${env.apiV2Url}/admin/user/unblock-user`, {id: id});
  }

  deleteUser(id: number): Observable<any> {
    return this.http.post(`${env.apiV2Url}/admin/user/delete-user`, {id: id});
  }

  list(): Observable<any> {
    return this.http.get(`${env.apiV2Url}/admin/user/list-users`).pipe(
      map((result: { data: any }) => {
        return {data: result.data.users};
      })
    );
  }

  loginManager(data: any): Observable<any> {
    return this.http.post(`${env.apiV2Url}/admin/manager/login-manager`, data).pipe(
      map((result : {data: {manager: any, token: any}}) => {
        return {
          user: result.data.manager,
          token: result.data.token
        };
      })
    );
  }

  registerCompanyV2(data: any): Observable<any> {
    return this.http.post(`${env.apiV2Url}/admin/company/register`, data);
  }

  sendHubSpotData(fields: any): Observable<any> {
    return this.http.post(`https://api.hsforms.com/submissions/v3/integration/submit/19627155/e79b2ca9-ae5e-442b-b33b-0a6039799123`, {
      fields: fields,
      context: {
        pageUri: document.URL,
        pageName: 'New Business Signup page'
      }
    })
  }

  verifyPromoCode(payload: IPromoCodeDto): Observable<any> {
    return this.http.post(`${env.apiV2Url}/admin/promo-code/verify`, payload)
  }

  resetManagerPassword(payload: IForgottenPasswordDto): Observable<any> {
    return this.http.post(`${env.apiV2Url}/admin/manager/reset-password-manager`, payload)
  }

  createNewPasswordUser(payload: IResetPasswordDto): Observable<any> {
    return this.http.post(`${env.apiV2Url}/admin/user/update-password-user-external`, payload)
  }

  updatePasswordManagerExternal(payload: IResetPasswordDto): Observable<any> {
    return this.http.post(`${env.apiV2Url}/admin/manager/update-password-manager-external`, payload)
  }

  confirmUserEmail(payload: IConfirmEmailDto): Observable<any> {
    return this.http.post(`${env.apiV2Url}/admin/user/confirm-user-email`, payload)
  }

  confirmManagerEmail(payload: IConfirmEmailDto): Observable<any> {
    return this.http.post(`${env.apiV2Url}/admin/manager/confirm-email-manager`, payload)
  }
}
