import { NativeDateAdapter } from '@angular/material/core';
import { MatDateFormats } from '@angular/material/core';
import moment from 'moment';
import { Injectable } from "@angular/core";

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {

  format(date: Date, displayFormat: Object): string {
      if (displayFormat === 'input') {

        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;

      } else {
        return date.toLocaleDateString('bg-BG', displayFormat);
      }
  }

  parse(value: any): Date | null {
      const date = moment(value, 'DD/MM/YYYY');
      return date.isValid() ? date.toDate() : null;
  }
}

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
    display: {
      dateInput: 'input',
      monthYearLabel: { year: 'numeric', month: 'long' },
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMM YYYY'
    }
};

