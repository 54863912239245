export enum BusinessType {
  Bakery = 1,
  Hotel = 2,
  RestaurantCathering = 3,
  Sushi = 4,
  ButcherShop = 5,
  FruitVegetable = 6,
  Buffet = 7,
  GroceryStore = 8,
  FishStore = 9,
  GasStation = 10,
  Grower = 11,
  Other = 12
}
