import { LOCAL_STORAGE_USER_KEY } from '../config/app.config';
import { Roles } from '../enums/roles.enum';

export function hasRole(allowedRoles: Roles[]) {
  const currentUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
  if (!currentUser) {
    return false;
  }
  const userRole = currentUser.role;
  return allowedRoles.indexOf(userRole) > -1;
}
