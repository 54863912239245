import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';

import { Subscription } from 'rxjs';

import { SidenavService } from './core/sidenav.service';
import { hasRole } from './shared/helpers/has-role';
import { Roles } from './shared/enums/roles.enum';
import { DEFAULT_LANG_KEY, LOCAL_STORAGE_LANG_KEY, SIDENAV_BREAKING_POINT } from './shared/config/app.config';
import { environment as env } from '../environments/environment';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { IBusiness } from './shared/declarations/company';
import { UserService } from './core/user.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{ provide: Window, useValue: window }]
})
export class AppComponent implements AfterViewInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  @ViewChild('sidenav') sidenavComponent!: MatSidenav;

  constructor(
    private readonly router: Router,
    private readonly observer: BreakpointObserver,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly sidenav: SidenavService,
    private readonly userService: UserService,
    private gtmService: GoogleTagManagerService,
    private translateService: TranslateService,
    private titleService: Title,
    private window: Window
  ) {
    this.translateService.setDefaultLang(DEFAULT_LANG_KEY);
    this.titleService.setTitle(env.html_title);
    if (env.isProduction) {
      (function (h: any, o: any, t: any, j, a, r) {
        h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
        h._hjSettings = { hjid: 2516256, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script'); r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

      this.subscriptions.add(this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (this.window['gtag']) {
            this.window['gtag']('set', 'page', event.urlAfterRedirects);
            this.window['gtag']('event', 'page_view', {
              page_title: event.urlAfterRedirects,
              page_location: event.urlAfterRedirects
            });

            // Google Tag Manager
            const gtmTag = {
              event: 'page',
              pageName: event.url
            };
            this.gtmService.pushTag(gtmTag);
          }

          if (this.window['fbq']) {
            this.window['fbq']('track', 'PageView');
          }
        }
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    if (this.isManagerLogged) {
      this.sidenav.set(this.sidenavComponent);
      this.observer.observe([`(max-width: ${SIDENAV_BREAKING_POINT})`]).subscribe((res) => {
        if (res.matches) {
          this.sidenav.setToMobile();
          this.sidenav.close();
        } else {
          this.sidenav.setToDesktop();
          this.sidenav.open();
        }
        this.changeDetectorRef.detectChanges();
      });
    }
  }
  get isManagerLogged() {
    return this.userService.isManagerLogged();
  }
  get userIsOnInventoryRoute() {

    if (this.router.url.indexOf('inventory') > -1) return true;
    if (this.selectedCompany) return true;

    return false;
  }

  get sideMenuIsMobile(): boolean {
    return this.sidenav.isMobile();
  }

  get isAdmin(): boolean {
    return hasRole([Roles.Admin]);
  }

  get selectedCompany(): IBusiness {
    return this.userService.temporaryCompany;
  }
}
