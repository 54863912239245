<div class="row justify-content-center">
  <div>
    <div class="upload-container" appDnd (fileDropped)="onImagesChange($event)"
      [class.cover-photo]="type === photoTypeEnum.Cover">
      <input type="file" #fileUploadInput id="fileUploadInput" (change)="onImagesChange($event)" [hidden]="disabled"/>
      <ng-container *ngIf="photos && photos.length; else hasPhoto">
        <div *ngFor="let photo of photos; let i = index" class="text-center">
          <div class="media-image" [class.logo]="type === photoTypeEnum.Logo"
            [style.background-image]="sanitize(photo.path)">
            <button [hidden]="disabled" type="button" class="btn btn-danger delete-button" (click)="deleteImage(photo, i)">
              <i class="fa fa-trash mr-2" aria-hidden="true"></i> {{'DELETE' | translate}}
            </button>
          </div>
        </div>
      </ng-container>
      <ng-template #hasPhoto>
        <div class="svg-bg" [class.svg-logo]="type === photoTypeEnum.Logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="51" height="47" viewBox="0 0 51 47">
            <defs>
              <style>
                .a {
                  fill: #b7ddff;
                }
              </style>
            </defs>
            <g transform="translate(0 -0.492)">
              <path class="a"
                d="M13.255,171.114a7.952,7.952,0,0,1-7.512-5.489l-.074-.247a7.958,7.958,0,0,1-.367-2.332v-14.67L.156,165.8a4.894,4.894,0,0,0,3.376,5.928l32.8,8.91a4.8,4.8,0,0,0,1.222.159,4.74,4.74,0,0,0,4.583-3.518l1.911-6.164Zm0,0"
                transform="translate(0 -133.304)" />
              <path class="a" d="M153.624,73.1a4.3,4.3,0,1,0-4.3-4.3A4.307,4.307,0,0,0,153.624,73.1Zm0,0"
                transform="translate(-134.483 -57.545)" />
              <path class="a"
                d="M122.974.492H90.7A5.387,5.387,0,0,0,85.32,5.872V29.539A5.387,5.387,0,0,0,90.7,34.918h32.274a5.387,5.387,0,0,0,5.379-5.379V5.872A5.387,5.387,0,0,0,122.974.492ZM90.7,4.8h32.274a1.076,1.076,0,0,1,1.076,1.076V21.146l-6.8-7.931a3.854,3.854,0,0,0-2.885-1.323,3.761,3.761,0,0,0-2.874,1.358L103.5,22.841l-2.6-2.6a3.777,3.777,0,0,0-5.336,0L89.624,26.18V5.872A1.076,1.076,0,0,1,90.7,4.8Zm0,0"
                transform="translate(-77.353)" />
            </g>
          </svg>
        </div>
      </ng-template>

      <div [hidden]="disabled">
        <h3>{{'PULL_PICTURE_TO_INSERT' | translate}}</h3>
        <h3>{{'OR' | translate}}</h3>
        <label for="fileUploadInput">{{ buttonText || 'SELECT_FILE' | translate }}</label>
      </div>
      <br>
      <small>({{'ACCEPTED_IMAGES' | translate}} <b>*.jpg</b>, <b>*.png</b>, <b>*.gif</b>, <b>*.tif</b> {{'MAXIMUM_SIZE' | translate}} <b>{{'UP_TO_5_MB' | translate}}</b>)</small>
      <br>
    </div>
  </div>
</div>
