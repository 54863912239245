import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';

import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store, Select } from '@ngxs/store';

import { Observable, Subscription } from 'rxjs';

import { UserService } from '../../../core/user.service';
import { Roles } from '../../../shared/enums/roles.enum';
import { hasRole } from '../../../shared/helpers/has-role';
import { IManager } from '../../declarations/user-types';
import { ConfirmActionComponent } from '../../../shared/components/confirm-action/confirm-action.component';
import { DEFAULT_ERROR_HEADING, DEFAULT_LANG_KEY, DEFAULT_MODAL_OPTIONS, DEFAULT_MODAL_SMALL_WIDTH, DEFAULT_SUCCESS_HEADING, LOCAL_STORAGE_LANG_KEY } from '../../../shared/config/app.config';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from '../../../core/company.service';
import { IBusiness } from '../../../shared/declarations/company';
import { IRestaurant } from '../../../shared/declarations/restaurant';
import { LoadSettings } from '../../../shared/state/settings.actions';
import { resolveLanguage, validateCompanyDataAndReturnErrors } from '../../../shared/helpers/utils';

@Component({
  selector: 'app-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.scss']
})

export class NavbarComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();
  @Input() inputSideNav: MatSidenav;
  @Output() onToggleMenu = new EventEmitter<boolean>();

  @Select(state => state.settings.restaurants) restaurants$: Observable<IRestaurant[]>;

  roles = Roles;
  manager: IManager;
  notificationsCount: number;
  selectPlace: FormControl;

  constructor(
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly dialog: MatDialog,
    private readonly toastrService: ToastrService,
    private readonly companyService: CompanyService,
    private readonly store: Store
  ) {
    this.selectPlace = new FormControl();
  }

  ngOnInit() {
    this.subscriptions.add(this.selectPlace.valueChanges.subscribe((value) => {
      this.userService.setSelectedResturant(value);
    }));
    this.restaurants$.subscribe((restaurants) => {
      if (restaurants) {
        let restaurant = restaurants[0];
        if (this.userService.selectedResturant?.id) {
          const currentSelection = restaurants.find((r) => r.id === this.userService.selectedResturant.id);
          if (currentSelection) {
            restaurant = currentSelection;
          }
        }
        this.selectPlace.setValue(restaurant, { emitEvent: true });
      }
    });

    if (this.isAdmin) {

      this.subscriptions.add(this.userService.onTemporaryCompanyChange.subscribe((result) => {
        this.store.dispatch(new LoadSettings());
      }));
    } else {
      this.store.dispatch(new LoadSettings());
    }

    this.subscriptions.add(this.userService.manager.subscribe((currentManager) => {
      if (currentManager) {
        this.manager = currentManager;
      } else {
        this.manager = this.userService.getLoggedManager();
      }
    }));

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  isCurrentState(targetState) {
    if (Array.isArray(targetState)) {
      return targetState.indexOf(this.router.url) > -1;
    }
    return this.router.url === targetState;
  }

  toggleMenu() {
    this.onToggleMenu.emit(true);
  }

  activateAccount(): void {

    const errors = validateCompanyDataAndReturnErrors(this.store);

    if (errors) {
      alert(`Missing fields: \n ${[...errors].join('\n')}`)
    }

    const dialogRef = this.dialog.open(ConfirmActionComponent, {
      ...DEFAULT_MODAL_OPTIONS,
      width: DEFAULT_MODAL_SMALL_WIDTH
    });

    this.subscriptions.add(dialogRef.afterClosed().subscribe((action: boolean) => {
      if (action) {
        this.subscriptions.add(this.companyService.activateCompany(this.selectedCompany.id).subscribe(() => {
          const successMsg = new Map([
            ['bg', 'Потребителят е еактивиран успешно'],
            ['en', 'User activated successfully']
          ])
          this.toastrService.success(successMsg.get(resolveLanguage()), DEFAULT_SUCCESS_HEADING);
          this.userService.setTemporaryCompany({
            ...this.selectedCompany,
            isActive: true
          });
        }, () => {
          const errorMsg = new Map([
            ['bg', 'Моля опитайте пак'],
            ['en', 'Please try again']
          ])
          this.toastrService.error(errorMsg.get(resolveLanguage()), DEFAULT_ERROR_HEADING);
        }));
      }
    }));
  }

  disableAccount(): void {
    const dialogRef = this.dialog.open(ConfirmActionComponent, {
      ...DEFAULT_MODAL_OPTIONS,
      width: DEFAULT_MODAL_SMALL_WIDTH
    });

    this.subscriptions.add(dialogRef.afterClosed().subscribe((action: boolean) => {
      if (action) {
        this.subscriptions.add(this.companyService.disableCompany(this.selectedCompany.id).subscribe(() => {
          const successMsg = new Map([
            ['bg', 'Потребителят е деактивиран успешно'],
            ['en', 'User deactivated successfully']
          ])
          this.toastrService.success(successMsg.get(resolveLanguage()), DEFAULT_SUCCESS_HEADING);
          this.userService.setTemporaryCompany({
            ...this.selectedCompany,
            isActive: false
          });
        }, () => {
          const errorMsg = new Map([
            ['bg', 'Моля опитайте пак'],
            ['en', 'Please try again']
          ])
          this.toastrService.error(errorMsg.get(resolveLanguage()), DEFAULT_ERROR_HEADING);
        }));
      }
    }));
  }

  get sideMenuIsMobile(): boolean {
    return this.inputSideNav.mode === 'over';
  }

  get isManager() {
    return hasRole([Roles.Manager]);
  }

  get isAdmin(): boolean {
    return hasRole([Roles.Admin]);
  }

  get selectedCompany(): IBusiness {
    return this.userService.temporaryCompany;
  }

  get isCompanySelected(): boolean {
    return !!this.userService.temporaryCompany;
  }

  get userIsOnInventoryRoute() {
    return this.router.url.indexOf('inventory') > -1;
  }

  get avatar() {
    if (this.manager) {
      return `https://eu.ui-avatars.com/api/?name=${this.manager.firstName} ${this.manager.lastName}`;
    } else {
      return `https://eu.ui-avatars.com/api/?name=user`;
    }
  }
}
