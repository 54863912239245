import { MatDialogConfig } from '@angular/material/dialog';

export const LOCAL_STORAGE_USER_KEY = 'f-platform-user';
export const LOCAL_STORAGE_TOKEN_KEY = 'f-platform-userToken';
export const LOCAL_STORAGE_LANG_KEY = 'f-platform-lang';
export const DEFAULT_LANG_KEY = 'bg';
export const COOKIE_TOKEN_KEY = 'f-platform-cookie';
export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';
export const DEFAULT_DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm';
export const DEFAULT_MODAL_WIDTH = '500px';
export const DEFAULT_MODAL_SMALL_WIDTH = '350px';
export const DEFAULT_MODAL_MAX_HEIGHT = '85vh';
export const DEFAULT_LOCALE = 'en-US';
export const SIDENAV_BREAKING_POINT = '992px';
export const GTM_DASHBOARD_ID = 'GTM-54D8DMQ';
export const RECAPTCHA_API_KEY = '6LfGIFwnAAAAAOoqprAqUdkv0XP4I9vE1g-vbbSP';

export const MAX_UPLOAD_IMAGE_SIZE_IN_MB: number = 5;

export const DEFAULT_MODAL_OPTIONS: MatDialogConfig = {
  hasBackdrop: true,
  disableClose: true,
  panelClass: 'modal-dialog-container',
  width: DEFAULT_MODAL_WIDTH,
  maxHeight: DEFAULT_MODAL_MAX_HEIGHT
};

export const TOASTR_DEFAULT_OPTIONS = {
  preventDuplicates: true,
};

export const DEFAULT_SPINNER_OPTIONS = {
  diameter: 30
};

export const BASE_COLOR = '4cae6c';
export const DEFAULT_SUCCESS_HEADING = (() => {
  const translations = new Map([
    ['bg', 'Успех'],
    ['en', 'Success'],
    ['ro', 'Succes'],
  ]);
  const locale = localStorage.getItem(LOCAL_STORAGE_LANG_KEY) || DEFAULT_LANG_KEY;
  return translations.get(locale);
})();

export const DEFAULT_SUCCESS_MESSAGE = (() => {
  const translations = new Map([
    ['bg', 'Данните са успешно запазени'],
    ['en', 'The data was saved successfully'],
    ['ro', 'Datele au fost salvate cu succes'],
  ]);
  const locale = localStorage.getItem(LOCAL_STORAGE_LANG_KEY) || DEFAULT_LANG_KEY;
  return translations.get(locale);
})();

export const DEFAULT_ERROR_HEADING = (() => {
  const translations = new Map([
    ['bg', 'Възникна грешка'],
    ['en', 'An error occurred'],
    ['ro', 'A aparut o eroare'],
  ]);
  const locale = localStorage.getItem(LOCAL_STORAGE_LANG_KEY) || DEFAULT_LANG_KEY;
  return translations.get(locale);
})();
export const DEFAULT_ERROR_MESSAGE = (() => {
  const translations = new Map([
    ['bg', 'Моля, опитайте пак'],
    ['en', 'Please try again'],
    ['ro', 'Vă rugăm să încercați din nou'],
  ]);
  const locale = localStorage.getItem(LOCAL_STORAGE_LANG_KEY) || DEFAULT_LANG_KEY;
  return translations.get(locale);
})();

export const TERMS_URL = '';
export const ASSETS_PATH = 'assets/img/';
export const UPLOADS_PRODUCTS_PATH = 'products';
export const UPLOADS_RESTAURANTS_PATH = 'restaurants';
export const PIN_LENGTH = 6;
